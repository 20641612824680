import React from "react";


function CheckBox(props) {

  const handleChange = (event) => {
    if (props.onChange) {
        props.onChange(event);
    }
  }
  return (
    <div className="flex gap-2  items-center">
    <div className="flex justify-center  relative ">
        <div className="absolute inset-0 bg-gradient-to-b from-green to-blue m-[-1.9px] rounded-md"></div>
        <input type="checkbox" onChange={handleChange} className="relative z-[2] w-4 h-4 text-green-600 bg-gray-100 border-green rounded" />
    </div>
    <span className="text-[10px] text-white ">by signing up you agree with our terms and conditions</span>
</div>
  );
}

export default CheckBox;
