import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import Nav from "../components/Nav";
import Header from "../components/Header";
import Footer from "../components/footer.component";
import BreadCrump from "../components/BreadCrump.component";
import FormInput from "../components/FormInput";
import Cart from "../components/cart.compontent";
import Overlay from "../components/Overlay";
import SearchInput from "../components/search-input.component";
import MobileMenu from "../components/menu.component";
import BottomNav from "../components/bottom-nav.component";
import Loader from "../components/Loader";

import ProfileIcon from "../assets/icons/profile.svg";
import PasswordIcon from "../assets/icons/password-gray.svg";
import EmailIcon from "../assets/icons/email-gray.svg";

import api from "../statics/api";
import { getCookie } from "../App";
import { set_username } from "../reducers/username";
import { set_cart } from "../reducers/cart";

function ProfilePage() {
    const [formData, setFormData] = useState({ username: "", email: "", password: "", new_password: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const loadProfileData = async () => {
        setIsLoading(true);
        const session = getCookie("session");
        if (!session) return;

        const requestData = new FormData();
        requestData.append("session", session);

        try {
            const response = await fetch(`${api}get_profile_data.php`, {
                method: "POST",
                body: requestData,
            });

            if (!response.ok) {
                console.error("Failed to fetch:", response.statusText);
                return;
            }

            const responseData = await response.json();
            setIsLoading(false);

            if (responseData.res.state) {
                setFormData({
                    username: responseData.res.username,
                    email: responseData.email,
                });

                if (responseData.res.msg === "New session") {
                    const expiryDate = new Date(responseData.exp);
                    document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                    localStorage.setItem("fullname", responseData.res.username);
                    dispatch(set_username(responseData.res.username));
                }
            } else {
                handleSessionExpired();
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleSave = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setIsLoading(true);
        const session = getCookie("session");
        if (!session) return;

        const requestData = new FormData();
        requestData.append("session", session);

        const profileData = {
            new_username: formData.username,
        };

        if (formData.password) profileData.password = formData.password;
        if (formData.new_password) profileData.new_password = formData.new_password;

        requestData.append("data", JSON.stringify(profileData));

        try {
            const response = await fetch(`${api}edit_profile_data.php`, {
                method: "POST",
                body: requestData,
            });

            if (!response.ok) {
                console.error("Failed to save:", response.statusText);
                return;
            }

            const responseData = await response.json();
            setIsLoading(false);


            if (responseData.state) {
                toast.success(responseData.msg)

                if (responseData.res.state) {
                    if (responseData.res.msg === "New session") {
                        const expiryDate = new Date(responseData.exp);
                        document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                        localStorage.setItem("fullname", responseData.res.username);
                        dispatch(set_username(responseData.res.username));
                    }
                } else {
                    handleSessionExpired();
                }
            }else {
                toast.error(responseData.msg)
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleSessionExpired = async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict`;
        localStorage.setItem("cart", "[]");
        dispatch(set_cart([]));
        window.location.reload();
    };

    const handleSearchToggle = () => {
        setIsSearching((prev) => !prev);
    };

    useEffect(() => {
        loadProfileData();
    }, []);

    const breadcrumbItems = [
        { name: "HOME", to: "/" },
        { name: "My Profile", to: "/my-account/profile" },
    ];

    return (
        <>
            <Helmet>
                <title>PuZzLe Technology - My Profile</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Nav />
            <Header onSearchClick={handleSearchToggle} />
            <BreadCrump items={breadcrumbItems} />

            <main className="relative overflow-x-auto px-mobile md:px-tablet xl:px-pc py-6 md:py-10 min-h-96 max-w-[1400px] 2xl:mx-auto">
                <h2 className="text-white font-bold text-lg">My Profile</h2>
                <form action="" className="bg-bw-02 p-6 rounded-2xl mt-6">
                    <h3 className="text-white">Profile Data</h3>

                    <div className="flex gap-2 flex-wrap">
                        <div className="flex-1">
                            <FormInput
                                placeHolder="Write your full name here ..."
                                img={ProfileIcon}
                                type="text"
                                name="Full Name"
                                onChange={(e) => handleInputChange("username", e.target.value)}
                                value={formData.username}
                            />
                        </div>
                        <div className="flex-1">
                            <FormInput
                                placeHolder="Write your email address here ..."
                                img={EmailIcon}
                                type="email"
                                name="Email Address"
                                value={formData.email}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="my-4"></div>
                    <h3 className="text-white">Change Password (Optional)</h3>

                    <div className="flex gap-2 flex-wrap">
                        <div className="flex-1">
                            <FormInput
                                placeHolder="Write your password here ..."
                                img={PasswordIcon}
                                type="password"
                                name="Current Password"
                                onChange={(e) => handleInputChange("password", e.target.value)}
                            />
                        </div>
                        <div className="flex-1">
                            <FormInput
                                placeHolder="Write your new password here ..."
                                img={PasswordIcon}
                                type="password"
                                name="New Password"
                                onChange={(e) => handleInputChange("new_password", e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex">
                        <button
                            className="bg-blue rounded-lg flex-1 text-white font-bold py-2 mt-2"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                        <div className="flex-1"></div>
                    </div>
                </form>
            </main>

            {isSearching && (
                <>
                    <SearchInput />
                    <div onClick={() => setIsSearching(false)}>
                        <Overlay />
                    </div>
                </>
            )}

            {isLoading && (
                <>
                    <Overlay />
                    <Loader />
                </>
            )}
            <ToastContainer></ToastContainer>
            <Cart />
            <Footer />
            <MobileMenu />
            <BottomNav currentPage="/my-account" />
        </>
    );
}

export default ProfilePage;
