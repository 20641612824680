import { createSlice } from '@reduxjs/toolkit';

export const buildYourPCSlice = createSlice({
  name: 'buildYourPC',
  initialState: {
    products: [],
    selectedProducts: []
  },
  reducers: {
    add: (state, action) => {
      state.products.push(action.payload);
    },
    set: (state, action) => {
      state.products = action.payload
    },
    remove: (state, action) => {
      state.products = state.products.filter(product => product.sku !== action.payload);
    },
    remove_all: (state, action) => {
      state.products = []
    },
    select_color: (state, action) => {
      let newProducts = [...state.products];
      let productIndex;
      newProducts.map((ele,index) => {
        // Get product index to upadte
        if (ele.sku === action.payload.sku) {
          productIndex = index
        }
      });
      newProducts[productIndex] = {...newProducts[productIndex], color: action.payload.newColor}
      state.products = newProducts
    }
  }
});

// Action creators are generated for each case reducer function
export const { add, remove, remove_all, select_color, set } = buildYourPCSlice.actions;

export default buildYourPCSlice.reducer;
