import { useEffect, useState } from "react";
import RightIcon from "../assets/icons/right-icon.svg"
import LeftIcon from "../assets/icons/left-icon.svg"

export default function Carousel({ slides }) {
  let [current, setCurrent] = useState(0);

  let previousSlide = () => {
    if (current === 0) setCurrent(slides.length - 1);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000); // 
    return () => clearInterval(interval);
  }, [current]); // Run effect whenever current slide changes


  return (
    <div className="overflow-hidden relative">
      <div
        className={`flex transition ease-out duration-700`}
        style={{
          transform: `translateX(-${current * 100}%)`,
        }}
      >
        {slides.map((s) => {
          return <img src={s} />;
        })}
      </div>

      <div className="absolute top-0 h-full w-full justify-between items-center flex text-white md:px-10 px-4 text-3xl">
        <button onClick={previousSlide} className="flex justify-center items-center md:w-[56px] md:h-[56px] w-[40px]  h-[40px] relative">
          <div className="absolute bg-white inset-0 rounded-full opacity-15"></div>
          <img src={LeftIcon} alt=">" className="absolute z-10"></img>
        </button>
        <button onClick={nextSlide} className="flex justify-center items-center md:w-[56px] md:h-[56px] w-[40px]  h-[40px] relative">
          <div className="absolute bg-white inset-0 rounded-full opacity-15"></div>
          <img src={RightIcon} alt=">" className="absolute z-10"></img>
        </button>
      </div>

      <div className="absolute bottom-0 py-4 flex justify-center gap-2 w-full">
        {slides.map((s, i) => {
          return (
            <div
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + i}
              className={`rounded-full w-2 h-2 md:h-[10px] md:w-[10px] cursor-pointer  ${
                i == current ? "bg-white" : "bg-gray-500"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}