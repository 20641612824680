import Menu from "../assets/icons/menu.svg";
import Logo from "../assets/icons/logo.svg";
import categories from "../statics/nav-items";
import Search from "../assets/icons/search.svg";
import Wishlist from "../assets/icons/wishlist.svg";
import Cart from "../assets/icons/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import { set_cart_state } from "../reducers/cart";
import { set_mobile_menu } from "../reducers/mobilemenu";
import { Link } from "react-router-dom";

function Header({ onSearchClick }) {
  const cart = useSelector((state) => state.cart);
  const menu = useSelector((state) => state.mobilemenu);
  const dispatch = useDispatch();

  return (
    <header className="flex px-mobile md:px-tablet xl:px-pc   py-[16px] bg-bw-02 border-[1px] border-bw-03 justify-between align-center md:flex-row-reverse md:gap-[100px] transition-colors duration-700">
      <div
        className="p-[5px] flex flex-col justify-center align-center xl:hidden relative"
        onClick={() => set_mobile_menu(dispatch(set_mobile_menu(!menu.state)))}
      >
        <img src={Menu} className="w-[24px]" alt="menu" />
        <div className="absolute select-none inset-0 bg-white opacity-0 rounded active:opacity-15 transition duration-150 ease-in-out"></div>
      </div>

      <div className="relative hidden xl:flex gap-[12px] items-center select-none">
        <img
          src={Search}
          className="w-[24px] cursor-pointer"
          onClick={onSearchClick}
          alt="search"
        />
        <Link to={"/wishlist"}>
          <img
            src={Wishlist}
            className="w-[24px] h-[24px] cursor-pointer"
            alt="wishlist"
          />
        </Link>
        <div
          className="flex justify-center flex-shrink-0 cursor-pointer"
          onClick={() => set_cart_state(dispatch(set_cart_state(!cart.state)))}
        >
          <div className="absolute top-[-4px] right-[-4px] text-white rounded-full px-2 py-1 w-5 h-5 flex justify-center items-center text-[10px] font-bold bg-gradient-to-b from-green to-blue">
            {cart.items.length}
          </div>
          <img src={Cart} className="w-6 cursor-pointer" alt="cart" />
        </div>
      </div>

      <ul className="justify-between items-center hidden xl:flex gap-[30px]">
        <Link
          to="/"
          className="text-white text-center flex items-center font-['kensmark-03'] hover:text-gradient"
        >
          HOME
        </Link>

        {categories.map((category, index) => (
  <div key={index} className="group ">
    <Link
      to={`/categories`}
      className="text-white text-center flex items-center font-['kensmark-03'] hover:text-gradient"
    >
      {category.tag}
    </Link>
    <div className="absolute left-2 right-2 z-[100] max-h-0 opacity-55 group-hover:opacity-100 group-hover:max-h-[1000px] overflow-hidden duration-300 ease-in-out group-hover:flex group-hover:flex-col text-white rounded-lg shadow-lg font-['kensmark-03']">
      <div className="py-4"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 bg-bw-02 p-4">
        {[0, 1, 2, 3].map((col) => (
          <ul key={col} className="flex-1">
            {category.items
              .slice(
                Math.ceil((col * category.items.length) / 4),
                Math.ceil(((col + 1) * category.items.length) / 4)
              )
              .map((item, idx) => (
                <li key={idx} className="px-4 py-2 hover:bg-bw-03">
                  <Link to={`/category/${item.slug}`} className="flex gap-2">
                    <img src={item.icon} className="w-6 h-6" alt="icon" />
                    {item.name}
                  </Link>
                  {item.children && (
                    <ul className="mt-2 ml-4">
                      {item.children.map((child, childIdx) => (
                        <li key={childIdx} className="px-4 py-2 hover:bg-black">
                          <Link to={`/category${child.slug}`}>{child.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        ))}
      </div>
    </div>
  </div>
))}


        <Link
          to="/brands"
          className="text-white text-center flex items-center font-['kensmark-03'] hover:text-gradient"
        >
          BRANDS
        </Link>
        <Link
          to="/sales"
          className="text-white text-center flex items-center font-['kensmark-03'] hover:text-gradient"
        >
          FLASH SALES
        </Link>
        <Link
          to="/build-your-pc"
          className="text-white text-center flex items-center font-['kensmark-03'] hover:text-gradient"
        >
          BUILD YOUR PC
        </Link>
        <Link
          to="/category/bundles"
          className=" text-center flex items-center font-['kensmark-03'] hover:text-gradient text-blue"
        >
          Bundles
        </Link>
      </ul>

      <Link to={"/"}>
        <div className="flex gap-[8px]">
          <img src={Logo} alt="logo" />
        </div>
      </Link>

    </header>
  );
}

export default Header;
