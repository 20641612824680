import React, { useEffect, useRef, useState } from "react";
import  api from "../statics/api.js"
import Search from "../assets/icons/search.svg";
import 'react-toastify/dist/ReactToastify.css';
import ProductHorizontalSearchItem from "./product-horizontal-searchitem.component.jsx";
import RightIcon from "../assets/icons/arrow-right.svg"
import { useNavigate } from "react-router-dom";

function SearchInput() {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [resultContainer, showResultContainer] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    // Focus on the input element when the component mounts
    inputRef.current.focus();
  }, []);

  const handleSearch = async (query) => {
        setNoResultsFound(false);
        if (query.length > 0) {
            for (let i = 0; i < 100; i++) {
                try {
                    let response = await fetch(`${api}search_product_name.php?name=${query}&start=0&end=5&filters=[]`);
                    let responseData = await response.text();
                    responseData = JSON.parse(responseData);
                    if (responseData.state === false) {
                        setNoResultsFound(true);
                        setProducts([])
                        return;
                    }
                    console.log(responseData)
                    showResultContainer(true);
                    setProducts(responseData.products);
                    
                    return
                } catch (error) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        }
     
  };

  let typingTimer;

  const handleInputChange = (event) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      handleSearch(event.target.value);
    }, 500);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate("/search/" + inputRef.current.value);
      
    }
  };

  return (
    <div className="fixed z-50 top-40 left-[50%] translate-x-[-50%] w-[95%] flex flex-col">
        <div className="search-input px-4 py-[10px] flex gap-2  justify-between items-center rounded-lg  ">
            <input
                ref={inputRef} // Assign ref to the input element
                className="flex-1 font-['cairo'] border-none outline-none bg-transparent text-bw-04"
                placeholder="Search here ..."
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
            ></input>
            <img
                src={Search}
                alt="Search Icon"
                className="cursor-pointer"
                onClick={() => handleSearch(inputRef.current.value)}
            />
        </div>
        
        {
            products.length > 0 ? 
             <div className="mt-4 bg-white rounded-lg p-2 flex flex-col gap-2 overflow-y-auto md:h-96">
             {
                        products.map((ele) => {
                        return <div><ProductHorizontalSearchItem data={ele} onClick={()=>navigate(ele.slug)}></ProductHorizontalSearchItem></div>
                        })
             }
             <div className="mt-2 box-shadow-1  cursor-pointer font-[500] flex items-center justify-center gap-2 p-4 rounded-lg " onClick={()=>navigate(`/search/${inputRef.current.value}`)}>
                See All Results <img src={RightIcon} alt="icon"></img>
             </div></div>
            : null
        }
        
        {
            noResultsFound ?
            <div className="mt-4 bg-white rounded-lg flex justify-center items-center p-2  font-[500]">No Results Found</div>
        : null
        }
        
    </div>
   
  );
}

export default SearchInput;
