import PowerSupply from "../assets/images/power-supply.svg";
import Ram from "../assets/images/ram.svg";
import GraphicCard from "../assets/images/graphics-card.svg";
import Storage from "../assets/images/storage.svg";
import MotherBoard from "../assets/images/mother-board.svg";
import ComputerCase from "../assets/images/computer-case.svg";
import Accessories from "../assets/images/accessories.svg";
import Processors from "../assets/images/processors.svg";
import Laptops from "../assets/images/laptops.svg";
import Monitors from "../assets/images/monitors.svg";
import Bundles from "../assets/images/bundles.svg";


const categories = [
    {
        tag: "CATEGORIES",
        link_to: "/categories",
        items: [
        
            { name: "Bundles", slug: "bundles", icon: Bundles, children: [
                { name: "AMD Bundles", slug: "/bundles/amd-bundles" },
                { name: "INTEL Bundles", slug: "/bundles/intel-bundles" },
            ] },
            { name: "Monitors", slug: "monitors", icon: Monitors , children: [
                { name: "Monitors - VA", slug: "/monitors/monitors-va" },
                { name: "Monitors - TN", slug: "/monitors/monitors-tn" },
                { name: "Monitors - IPS", slug: "/monitors/monitors-ips" },
            ]},
            { name: "Laptops", slug: "laptops", icon: Laptops },
            {
                name: "Graphic Cards", slug: "graphic-cards", icon: GraphicCard,
                children: [
                    { name: "NVIDIA Graphics Cards", slug: "/graphic-cards/nvidia-graphics-cards" },
                    { name: "AMD Graphics Cards", slug: "/graphic-cards/amd-graphics-cards" },
                    { name: "INTEL Graphics Cards", slug: "/graphic-cards/intel-graphics-cards" }
                ]
            },
            { name: "Accessories", slug: "accessories", icon: Accessories,
                children: [
                    { name: "INTEL - AMD CPU FRAME", slug: "/accessories/intel-amd-cpu-frame" },
                    { name: "GPU HOLDER", slug: "/accessories/gpu-holder" },
                    { name: "THERMAL PASTE", slug: "/accessories/thermal-paste" },
                    { name: "GAMING HEADSET", slug: "/accessories/gaming-headset" },
                    { name: "GAMING MOUSE", slug: "/accessories/gaming-mouse" },
                    { name: "MOUSE PAD GAMING", slug: "/accessories/mouse-pad-gaming" },
                    { name: "GAMING Keyboard", slug: "/accessories/gaming-keyboard" },
                    { name: "Gamepads - Controllers PC", slug: "/accessories/gamepads-controllers-pc" }
                ]
            },
            { name: "PC Cooling", slug: "pc-cooling", icon: Accessories,
                children: [
                    { name: "CPU COOLER - AIR", slug: "/pc-cooling/cpu-cooler-air" },
                    { name: "CPU COOLER - LIQUID", slug: "/pc-cooling/cpu-cooler-liquid" },
                    { name: "FANS - PC", slug: "/pc-cooling/fans-pc" }
                ]
            },
            { name: "Power Supply", slug: "power-supply", icon: PowerSupply },
            { name: "Computer Case", slug: "computer-case", icon: ComputerCase,
                children: [
                    { name: "CASE + PSU COMBO", slug: "/computer-case/case-combo" },
                    { name: "CASE - NO PSU", slug: "/computer-case/case-no-psu" }
                ]
            },
            { name: "Storage", slug: "storage", icon: Storage,
                children: [
                    { name: "SSD SATA 2.5", slug: "/storage/ssd-sata-2.5" },
                    { name: "NVME - M2", slug: "/storage/nvme-m.2" },
                    { name: "External Hard", slug: "/storage/external-hard" },
                    { name: "Internal Hard", slug: "/storage/internal-hard",
                        children: [
                            { name: "HDD 3.5", slug: "/storage/internal-hard/hdd-3.5" },
                            { name: "HDD 2.5", slug: "/storage/internal-hard/hdd-2.5" }
                        ]
                    }
                ]
            },
            { name: "RAM", slug: "ram", icon: Ram,
                children: [
                    { name: "RAM PC", slug: "/ram/ram-pc" },
                    { name: "RAM LAPTOP", slug: "/ram/ram-laptop" }
                ]
            },
            { name: "Motherboard", slug: "motherboard", icon: MotherBoard,
                children: [
                    { name: "MOTHERBOARD INTEL", slug: "/motherboard/motherboard-intel" },
                    { name: "MOTHERBOARD AMD", slug: "/motherboard/motherboard-amd" }
                ]
            },
            { name: "CPU", slug: "cpu", icon: Processors,
                children: [
                    { name: "INTEL", slug: "/cpu/cpu-intel" },
                    { name: "AMD", slug: "/cpu/cpu-amd" }
                ]
            }
        ]
    }
];

export default categories;
