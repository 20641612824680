import React, { useState } from "react";
import StarGold from "../assets/icons/star-gold.svg";
import StarGray from "../assets/icons/star-gray.svg";
import CloseCircle from "../assets/icons/close-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { remove, set } from "../reducers/buildYourPC.js";
import { toast } from "react-toastify";

function BuildYourPCSelectedProduct(props) {

  const dispatch = useDispatch();
  const selectedProducts = useSelector((state) => state.buildYourPC.products);
  const [data, setData] = useState({ ...props.data });
  const goldStars = [];
  const grayStars = [];

  const handleStars = () => {
    // Populate goldStars and grayStars arrays
    for (let i = 0; i < Math.floor(data.avg_rating); i++) {
      goldStars.push(<img key={`gold-${i}`} src={StarGold} alt="gold star" />);
    }
    // Fill the remaining gray stars up to 5
    for (let i = 0; i < 5 - Math.floor(data.avg_rating); i++) {
      grayStars.push(<img key={`gray-${i}`} src={StarGray} alt="gray star" />);
    }
  };

  const checkDiscount = (color = data.color) => {
    for (const discount of data.discounts) {
      if (discount.hexcode === color) {
        return discount;
      }
    }
    return {}; // Return an empty object if no discount is found
  };

  const handleColorChange = (color) => {
    let discount = checkDiscount(color);
    if (discount !== data.discount) {
      // console.log("Update Global")
      // Update local and slice
      if (!discount.value) {
        setData({
          ...data,
          color: color,
          discountedPrice: data.price,
          discount: {},
        });
        const oldData = [...selectedProducts];
        let newData = oldData.map((ele, index) => {
          // Update Color and Discount Globaly iin the slice
          if (ele.sku === data.sku) {
            return {
              ...ele,
              discount: {},
              discountedPrice: data.price,
              color: color,
            };
          } else {
            return ele;
          }
        });
        dispatch(set(newData));
      }

      if (discount.type === "percent") {
        setData({
          ...data,
          color: color,
          discount: discount,
          discountedPrice: (1 - discount.value) * data.price,
        });
        const oldData = [...selectedProducts];
        let newData = oldData.map((ele, index) => {
          // Update Color and Discount Globaly iin the slice
          if (ele.sku === data.sku) {
            return {
              ...ele,
              discount: discount,
              discountedPrice: (1 - discount.value) * data.price,
              color: color,
            };
          } else {
            return ele;
          }
        });
        dispatch(set(newData));
      }
    }
  };

  const handleDecreasePuchaseQty = () => {
    if (data.qty === +data.min_purchase_qty) return
    const oldData = [...selectedProducts]
    let newData = oldData.map((ele)=>{
        if (ele.sku === data.sku) {
           return {...ele, qty: ele.qty-1}
        }
        return ele
    })
    dispatch(set(newData))
  }

  const handleIncreasePuchaseQty = () => {
    if (data.qty >= data.max_purchase_qty) return
        const oldData = [...selectedProducts]
        let newData = oldData.map((ele)=>{
            if (ele.sku === data.sku) {
               return {...ele, qty: ele.qty+1}
            }
            return ele
        })
        dispatch(set(newData))
  }

  handleStars();

  return (
    <div
      className={`relative p-2 s shadow-lg flex gap-2  duration-200  flex-1 items-center bg-black rounded-lg md:items-start`}
    >
    {/* Product Image */}
      <img
        src={data.img1}
        alt="product_image"
        className="rounded-lg w-16 h-16 xl:w-[124px] xl:h-[124px] "
      />
      
      {/* Product Details */}
      <div className="flex flex-col gap-2 flex-1">

        {/* Stars & no. Reviews */}
        <div className="flex">
            {goldStars}
            {grayStars}
            <span className="">({data.num_reviews || 0})</span>
        </div>

        {/* Product Title */}
        <span className="text-sm font-['poppins'] leading-[18px] ">
          {data && data.title.length > 50
            ? data.title.slice(0, 50) + "......"
            : data.title}
        </span>

        {/* Discout Value */}
        {!data.has_nodiscount && data.discount.value && (
          <div className="px-1 py-[2px] save-gradient w-14 rounded-[4px] flex items-center justify-center">
            <span className="font-['cairo'] text-xs">
              {data.discount.type === "amount"
                ? `Save ${data.discount.value} LE`
                : `Save ${data.discount.value * 100}%`}
            </span>
          </div>
        )}

        {/* Colors */}
        <div className="flex items-center gap-4"> 

            <span className="font-['cairo'] text-lg  font-bold">
              {data.colors && data.colors !== undefined && "Color:"}
            </span>

            <div className="flex gap-1">
              {data.colors &&
                data.colors !== undefined &&
                data.colors.split(",").map((color, index) => (
                  <div
                    key={index}
                    className={`w-6 h-6 relative rounded-lg cursor-pointer ${
                      data.color === color ? "border-gradient" : ""
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      handleColorChange(color);
                    }}
                  >
                    <div
                      className="absolute inset-0  rounded-lg"
                      style={{ backgroundColor: color }}
                    ></div>
                  </div>
                ))}
            </div>

        </div>

        {/* Price & Discounted Price */}
        <div className="gap-2 flex items-center font-['poppins'] flex-1">
          {!data.has_nodiscount && data.discount.value ? (
            <span className="text-base font-bold leading-[18px]">
              {data.discountedPrice} LE
            </span>
          ) : (
            <span className="text-base font-bold leading-[18px]">
              {data.price} LE
            </span>
          )}
          {!data.has_nodiscount && data.discount.value && (
            <span className="text-xs text-bw-04 line-through">
              {data.price} LE
            </span>
          )}
        </div>

        {/* Select Quantity */}
        <div className="select-none absolute right-2 bottom-1 flex items-center gap-4 my-2">

              <div className="flex gap-1">
                <div
                  className="w-6 h-6 md:w-10 md:h-10 bg-bw-02 border-[1.5px] border-bw-03 rounded-l-lg flex items-center justify-center cursor-pointer"
                  onClick={handleDecreasePuchaseQty}
                >
                  {data.qty  === +data.min_purchase_qty ? (
                    <span className="text-bw-03 font-['kensmark-03'] text-2xl">
                      -
                    </span>
                  ) : (
                    <span className="text-gradient font-['kensmark-03'] text-2xl">
                      -
                    </span>
                  )}
                </div>

                <div className="w-6 h-6 md:w-10 md:h-10 text-white font-['kensmark-03'] bg-bw-02 border-[1.5px] border-bw-03 flex items-center justify-center">
                  {data.qty}
                </div>
                <div
                  className="w-6 h-6 md:w-10 md:h-10 bg-bw-02 border-[1.5px] border-bw-03 rounded-r-lg flex items-center justify-center cursor-pointer"
                  onClick={handleIncreasePuchaseQty}
                >
                  {data.qty === +data.max_purchase_qty ? (
                    <span className="text-bw-03 font-['kensmark-03'] text-2xl">
                      +
                    </span>
                  ) : (
                    <span className="text-gradient font-['kensmark-03'] text-2xl">
                      +
                    </span>
                  )}
                </div>
              </div>
            </div>

      </div>

      <img
        src={CloseCircle}
        className="absolute top-2 right-2 cursor-pointer"
        onClick={() => dispatch(remove(data.sku))}
        alt="remove_icon"
      />
    </div>
  );
}

export default BuildYourPCSelectedProduct;
