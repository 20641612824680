import Header from "../components/Header.jsx";
import Nav from "../components/Nav.jsx";
import { useEffect, useState } from "react";
import api from "../statics/api.js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader.jsx";
import Ovarlay from "../components/Overlay.jsx";
import { useDispatch } from "react-redux";
import SearchInput from "../components/search-input.component.jsx";
import Overlay from "../components/Overlay.jsx";
import ProductVertical from "../components/product-vertical.component.jsx";
import Cart from "../components/cart.compontent.jsx";
import MobileMenu from "../components/menu.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";
import Footer from "../components/footer.component.jsx";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function BrandsPage() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();

  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };

  const handleRequest = async () => {
    for (let i = 0; i < 100; i++) {
      try {
        let response = await fetch(`${api}get_brands.php`, {
          method: "GET",
        });
        let responseData = await response.text();
        responseData = JSON.parse(responseData);
        if (responseData.state) {
          setBrands(responseData["brands"]);
          return;
        }
        return;
      } catch (error) {
        console.log(error);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };

  const BrandsSkeleton = () => {
    return (
      <div className="mt-10 flex flex-col gap-6">
        <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-4 xl:grid-cols-8 2xl:grid-cols-12  border">
          {Array.from({ length: 56 }).map((___, index) => {
            return (
              <div
                className={
                  "min-w-[100px] animate-pulse  p-2 bg-bw-02 flex relative flex-col gap-2 rounded-lg flex-1 items-center h-full justify-center border"
                }
              >
                <div role="status" class=" ">
                  <div class="h-32 bg-gray-200 rounded-lg mb-4"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  useEffect(() => {
    handleRequest();
  }, []);
  return (
    <>
      <Nav></Nav>
      <Header onSearchClick={handleSearchClick}></Header>

      <Helmet>
        <title>PuZzLe Technology | Brands</title>
        <meta name="description" content="Explore Our Brands" />
      </Helmet>

      <div className="mt-6 md:15  px-4 min-h-80 px-mobile md:px-tablet xl:px-pc py-6 md:py-10">
        <h1 className="text-white text-2xl font-bold font-['kensmark-03'] mb-4">
          Brands
        </h1>
        {brands.length === 0 ? (
          <BrandsSkeleton></BrandsSkeleton>
        ) : (
          <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-4 xl:grid-cols-8 2xl:grid-cols-12  border">
            {brands.map((ele) => {
              return (
                <Link to={"/brands/" + ele.name}>
                  <div
                    className="cursor-pointer p-2 flex flex-col items-center h-full justify-center border"
                    title={ele.name}
                  >
                    <img
                      src={ele.img_url}
                      alt="brand_image"
                      className="rounded-md"
                      height={100}
                      width={100}
                    ></img>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
      {loading ? (
        <>
          <Loader></Loader>
          <Ovarlay></Ovarlay>
        </>
      ) : null}
      {isSearching ? (
        <>
          <SearchInput></SearchInput>
          <div onClick={() => setIsSearching(false)}>
            <Overlay></Overlay>
          </div>
        </>
      ) : null}
      <Footer></Footer>
      <Cart></Cart>
      <MobileMenu></MobileMenu>
      <BottomNav currentPage={""}></BottomNav>

      <ToastContainer />
    </>
  );
}

export default BrandsPage;
