import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StarGold from "../assets/icons/star-gold.svg";
import StarGray from "../assets/icons/star-gray.svg";
import HeartWhite from "../assets/icons/heart-white.svg";

function ProductHorizontalSearchItem(props) {
  const data = props.data;
  const goldStars = [];
  const grayStars = [];
  const [addedToWishlist, setAdded] = useState(false);
  const navigate = useNavigate();

  // Populate goldStars and grayStars arrays
  for (let i = 0; i < Math.floor(data.avg_rating); i++) {
    goldStars.push(<img key={i} src={StarGold} alt="gold star" />);
  }

  // If the average rating is not an integer, add one gray star
  if (data.avg_rating % 1 !== 0) {
    grayStars.push(<img key="gray" src={StarGray} alt="gray star" />);
  }

  // Fill the remaining gray stars up to 4
  const remainingGrayStars = 5 - Math.ceil(data.avg_rating);
  for (let i = 0; i < remainingGrayStars; i++) {
    grayStars.push(
      <img
        key={i + Math.floor(data.avg_rating)}
        src={StarGray}
        alt="gray star"
      />
    );
  }

  // Calculate discounted price and round to two decimal places
  let discountedPrice = data.price;
  if (!data.has_nodiscount && data.max_discount.type === "percent") {
    discountedPrice = (data.price * (1 - data.max_discount.value)).toFixed(2);
  }

  return (
    <div className="p-2 bg-white font-['poppins'] font-[500]  box-shadow-1 flex gap-2 rounded-lg flex-1 min-w-[330px] h-[140px] cursor-pointer" onClick={()=>navigate("/product/" + data.slug)}>
      <img src={data.img1} alt="product_image" className="rounded-lg w-[124px]" />
      <div className="flex flex-col gap-2 flex-1">
        <div className="flex justify-between">
          <div className="flex gap-[1px]">
            {goldStars}
            {grayStars}
            <span className="text-bw-06">({data.num_reviews || 0})</span>
          </div>
          {!addedToWishlist && (
            <img
              src={HeartWhite}
              alt="ico"
              onClick={() => {
                setAdded(true);
              }}
            />
          )}
        </div>
        <span className="text-sm  font-['poppins'] leading-[18px] ">
        {data.title.length > 50 ? data.title.slice(0,50) + "......" : data.title}
        </span>
        {!data.has_nodiscount && (
          <div className=" px-1 py-[2px] save-gradient w-14 rounded-[4px] flex items-center justify-center ">
            <span className=" font-['cairo'] text-xs">
              {data.max_discount.type === "percent"
                ? `Save ${data.max_discount.value * 100}%`
                : `Save ${data.max_discount.value}`}
            </span>
          </div>
        )}
        <div className="gap-2 flex items-center font-['poppins'] flex-1">
          <span className="text-base font-bold  leading-[18px]">
            {discountedPrice} LE
          </span>
          {!data.has_nodiscount && (
            <span className="text-xs text-bw-04 line-through">
              {data.price} LE
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductHorizontalSearchItem;
