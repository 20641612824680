import React from "react";
import arrowRightWhite from "../assets/icons/arrow-right-white.svg";
import { Link } from "react-router-dom";

function BreadCrump(props) {
  const BreadCrumpItems = props.items.map((ele, index) => {
    const isLastItem = index === props.items.length - 1;
    const itemClass = `font-['kensmark-03'] hover:text-gradient text-white font-bold pr-[40px] cursor-pointer  ${isLastItem ? 'text-gradient' : ''}`;

    return (
      <React.Fragment key={index}>
        <Link to={ele.to}>
          <span className={itemClass}>{ele.name}</span>
        </Link>
        {!isLastItem && <img src={arrowRightWhite} alt="icon" className="pr-[40px]"/>}
      </React.Fragment>
    );
  });

  return (
    <div className="bg-bw-02 flex-wrap gap-2 border-solid border-t-[1.5px] border-bw-03 flex py-2  px-mobile md:px-tablet xl:px-pc ">
      {BreadCrumpItems}
    </div>
  );
}

export default BreadCrump;
