import Nav from "../components/Nav";
import SearchInput from "../components/search-input.component";
import Overlay from "../components/Overlay";
import MobileMenu from "../components/menu.component";
import BottomNav from "../components/bottom-nav.component";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/footer.component";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import CartIcon from "../assets/icons/cart.svg";
import BreadCrump from "../components/BreadCrump.component";
import Profile from "../assets/icons/profile.svg";
import Logout from "../assets/icons/logout.svg";

import Wishlist from "../assets/icons/heart.svg";
import Cart from "../components/cart.compontent";
import api from "../statics/api";
import { getCookie } from "../App";
import { useDispatch } from "react-redux";
import { set_username } from "../reducers/username";
import { set_cart } from "../reducers/cart";

function Order() {
  const { query } = useParams();
  const [isSearching, setIsSearching] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderProducts, setOrderProducts] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breadcrumbItems = [
    { name: "HOME", to: "/" },
    { name: "My Account", to: "/my-account" },
    { name: "My Orders", to: "/my-account/orders" },
    { name: query, to: "/my-account/orders/" + query },
  ];

  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };

  // Get My Data
  const loadData = async () => {
    const session = getCookie("session");
    if (!session) {
      return;
    }

    let formData = new FormData();
    formData.append("session", session);
    formData.append("order_code", query);

    try {
      const response = await fetch(`${api}view_order.php`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseText = await response.text();
        const responseData = JSON.parse(responseText);

        if (responseData.res.state) {
          console.log(responseData.order);
          setOrderDetails(responseData.order.order_details);
          setOrderProducts(responseData.order.order_products);

          if (responseData.res.msg === "New session") {
            const expiryDate = new Date(responseData.exp);
            document.cookie = `session=${
              responseData.new_session
            }; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
            localStorage.setItem("fullname", responseData.res.username);
            dispatch(set_username(localStorage.getItem("fullname")));
          }
          return;
        } else {
          // Delete Session
          // toast.error("Please login again.")
          await new Promise((resolve) => setTimeout(resolve, 2000));
          document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict`;
          localStorage.setItem("cart", "[]");
          dispatch(set_cart([]));
          window.location.reload();
        }
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>PuZzLe Technology | Order {query}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Nav />
      <Header onSearchClick={handleSearchClick} />
      <BreadCrump items={breadcrumbItems} />

      <div class="relative overflow-x-auto mt-8 p-4 min-h-96 px-mobile md:px-tablet xl:px-pc py-6 md:py-10 min-h-96 ">
        <h1 className="font-['kensmark-03'] text-white text-xl ">
          Order (#{query})
        </h1>
        <div className=" relative  px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10 bg-white p-4">
          {/* Grid */}
          <div className="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-neutral-700">
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                Invoice
              </h2>
            </div>
            {/* Col */}

            <div className="inline-flex gap-x-2">
              {/* <a
                className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                  <polyline points="7 10 12 15 17 10" />
                  <line x1="12" x2="12" y1="15" y2="3" />
                </svg>
                Invoice PDF
              </a> */}
              {/* <a
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                href="#"
              >
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 6 2 18 2 18 9" />
                  <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                  <rect width="12" height="8" x="6" y="14" />
                </svg>
                Print
              </a>
               */}
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}

          {/* Grid */}
          <div className="grid md:grid-cols-2 gap-3">
            <div>
              <div className="grid space-y-3">
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Billed to:
                  </dt>
                  <dd className="text-gray-800 dark:text-neutral-200">
                    <a
                      className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium dark:text-blue-500"
                      href="#"
                    >
                      {orderDetails.email}
                    </a>
                  </dd>
                </dl>

   
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Shipping details:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-neutral-200">
                    <span className="block font-semibold">
                      {orderDetails.first_name + " " + orderDetails.last_name}
                    </span>
                    <address className="not-italic font-normal">
                      {orderDetails.apartment}
                      <br />
                      {orderDetails.street_address}
                      <br />
                      {orderDetails.city}
                      <br />
                      {orderDetails.country}
                      <br />
                    </address>
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}

            <div>
              <div className="grid space-y-3">
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Order Code:
                  </dt>
                  <dd className="text-gray-800 dark:text-neutral-200 font-bold">
                    #{orderDetails.order_code}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Order Status:
                  </dt>
                  <dd className="text-gray-800 dark:text-neutral-200 font-bold">
                    {orderDetails.order_state}
                  </dd>
                </dl>

                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Currency:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-neutral-200">
                    EGP - EG Pound
                  </dd>
                </dl>

                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Billing method:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-neutral-200">
                    {orderDetails.payment_method}
                  </dd>
                </dl>

                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Order date:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-neutral-200">
                    {orderDetails.created_at}
                  </dd>
                </dl>

                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                    Updated At:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-neutral-200">
                    {orderDetails.updated_at}
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}

          {/* Table */}
          <div className="flex flex-col mt-6">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg overflow-hidden dark:border-neutral-700">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                    <thead className="bg-gray-50 dark:bg-neutral-700">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                        >
                          Color
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                        >
                          Qty
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                        >
                          Discounts
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                      {orderProducts.map((ele, ind) => {
                        return (
                          <tr key={ind}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                              {ele["name"]}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200 ">
                              {ele["color"] ? (
                                <div
                                  className={`p-1 rounded-full border-strokedark dark:border-stroke border-[0.5px]`}
                                  style={{ backgroundColor: ele["color"] }}
                                ></div>
                              ) : (
                                "NO COLOR"
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                              {ele["price"]}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                              {ele["quantity"]}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                              {ele["discount"]}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                              {ele["quantity"] * ele["price"] - ele["discount"]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* End Table */}
          <div className="mt-2">
            <span className="whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
              Coupon Codes: {orderDetails.coupons_codes || "No coupon codes"}
            </span>
          </div>
          {/* Flex */}
          <div className="mt-8 flex sm:justify-end">
            <div className="w-full max-w-2xl sm:text-end space-y-2">
              {/* Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500 dark:text-neutral-500">
                    Subotal:
                  </dt>
                  <dd className="col-span-2 font-medium text-gray-800 dark:text-neutral-200">
                    {orderDetails.order_subtotal}
                  </dd>
                </dl>

                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500 dark:text-neutral-500">
                    Shpping:
                  </dt>
                  <dd className="col-span-2  text-gray-800 dark:text-neutral-200">
                    -{orderDetails.shipping}
                  </dd>
                </dl>

                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500 dark:text-neutral-500">
                    Product Discounts:
                  </dt>
                  <dd className="col-span-2 font-medium text-gray-800 dark:text-neutral-200">
                    -{orderDetails.products_discounts}
                  </dd>
                </dl>

                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500 dark:text-neutral-500">
                    Coupon Discounts:
                  </dt>
                  <dd className="col-span-2 font-medium text-gray-800 dark:text-neutral-200">
                    -{orderDetails.coupon_discounts}
                  </dd>
                </dl>

                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm font-bold">
                  <dt className="col-span-3 text-gray-500 dark:text-neutral-500">
                    Total:
                  </dt>
                  <dd className="col-span-2  text-gray-800 dark:text-neutral-200">
                    {orderDetails.order_subtotal -
                      orderDetails.shipping -
                      orderDetails.coupon_discounts -
                      orderDetails.products_discounts}
                  </dd>
                </dl>
              </div>
              {/* End Grid */}
            </div>
          </div>
          {/* End Flex */}
        </div>
      </div>

      <Cart></Cart>
      <Footer />
      <MobileMenu />
      <BottomNav currentPage="" />
    </>
  );
}

export default Order;
