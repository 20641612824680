import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "./Overlay";
import Search from "../assets/icons/search.svg";
import { Link, useNavigate } from "react-router-dom";
import { set_mobile_menu } from "../reducers/mobilemenu";
import Logo from "../assets/images/logo.svg";
import Facebook from "../assets/icons/facebook.svg";
import Whatsapp from "../assets/icons/whatsapp.svg";


function MobileMenu() {
  const menuState = useSelector((state) => state.mobilemenu.state);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SearchItem = () => {
    return (
      <div className="bg-bw-03 font-['cairo'] px-2 py-3 gap-[10px] flex justify-between mt-20 w-100 mx-auto w-full rounded-[4px]">
        <input
          ref={inputRef}
          onKeyDown={handleKeyPress}
          placeholder="Search your product here ..."
          className="bg-bw-03 outline-none border-none caret-white text-white text-sm"
        ></input>
        <img
          src={Search}
          alt="img"
          onClick={search}
          className="cursor-pointer"
        ></img>
      </div>
    );
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };
  const search = () => {
    const inputValue = inputRef.current.value;
    if (inputValue) {
      set_mobile_menu(dispatch(set_mobile_menu(false)));
      navigate("/search/" + inputRef.current.value);
    }
  };
  const handleCloseMenu = () => {
    set_mobile_menu(dispatch(set_mobile_menu(false)));
  }

  return (
    <div className="relative">
      <div
        className={
          "overflow-auto fixed flex flex-col  gap-10 px-6 top-0 bottom-0 left-0  w-[85%] max-w-[400px]  bg-bw-02 z-50 transition-all duration-300 p-3" +
          (menuState ? "menu-translate" : " translate-x-[-100%]")
        }
      >
        <SearchItem></SearchItem>
        <div className="flex flex-col gap-2">
          <div className="h-[2px] bg-bw-03 w-full"></div>
          <Link to={"/"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              Home
            </div>
          </Link>
          <div className="h-[2px] bg-bw-03 w-full"></div>

          <Link to={"/categories"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              Categories 
            </div>
          </Link>
          <div className="h-[2px] bg-bw-03 w-full"></div>

          <Link to={"/brands"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              Brands
            </div>
          </Link>
          <div className="h-[2px] bg-bw-03 w-full"></div>

          
          <Link to={"/sales"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              FLASH SALES
            </div>
          </Link>

          <div className="h-[2px] bg-bw-03 w-full"></div>
          <Link to={"/wishlist"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              WishList
            </div>
          </Link>
          <div className="h-[2px] bg-bw-03 w-full"></div>

          <Link to={"/category/bundles"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              Bundles
            </div>
          </Link>
          <div className="h-[2px] bg-bw-03 w-full"></div>
          <Link to={"/build-your-pc"}>
            <div className="font-['kensmark-03'] hover:text-gradient text-white" onClick={handleCloseMenu}>
              BUILD YOUR PC
            </div>
          </Link>
          <div className="h-[2px] bg-bw-03 w-full"></div>

         
        </div>

        <div className="mt-auto flex flex-col gap-2 items-center text-white mb-12">
          <img src={Logo} alt="logo" className="lg:w-16 2xl:w-[88px]" />
          <div className="flex flex-col items-center">
            <span className="flex justify-between w-full">
              <b>PuZzLe</b> TECH
            </span>
            <span className="text-xs">COMPUTER STORE</span>
            <div className="flex gap-1 mt-2">
              <img
                src={Whatsapp}
                className="w-5 hover:scale-105 transition duration-300 cursor-pointer"
                alt="Whatsapp Icon"
              />
              <img
                src={Facebook}
                className="w-5 hover:scale-105 transition duration-300 cursor-pointer"
                alt="Facebook Icon"
              />
            </div>
          </div>
        </div>
      </div>

      {menuState ? (
        <>
          <div
            onClick={() =>
              set_mobile_menu(dispatch(set_mobile_menu(!menuState)))
            }
          >
            <Overlay></Overlay>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default MobileMenu;
