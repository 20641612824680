import React, { useEffect, useRef, useState } from "react";
import Close from "../assets/icons/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { del_from_cart, set_cart, set_cart_state, set_qty } from "../reducers/cart";
import Overlay from "./Overlay";
import StarGold from "../assets/icons/star-gold.svg";
import StarGray from "../assets/icons/star-gray.svg";
import TrashGray from "../assets/icons/trash-gray.svg";
import TrashRed from "../assets/icons/trash-red.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import DelLottie from "../assets/lotties/del-from-cart.json"
import DoneLottie from "../assets/lotties/done.json"

import Loader from "../components/Loader";
import { getCookie } from "../App";
import api from "../statics/api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function Cart() {
  const cart = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const [delLottie, setDelLottie] = useState(false);
  const [doneLottie, setDoneLottie] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [cartDetails, setCartDetails] = useState({
    subtotal: 0,
    discount: 0,
    delivery: 0,
    length: 0
  })
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const doneLottiePlayerRef = useRef(null);
  let [data,setData] = useState([]);


  const handleIncreasePuchaseQty = async (index) => {
    if (  +data[index]["max_purchase_qty"] >= +data[index].qty) {
      const newData = [...data]; // Create a shallow copy of the original array
      newData[index] = { ...newData[index], qty: newData[index].qty + 1 }; // Update the quantity of the specific item
      setData(newData); // Update the state with the new array
      const session = getCookie("session");
      if (session) {
        setLoading(true);
        setOverlay(true);
        let postData = new FormData();
        postData.append("session", session);
        postData.append("product", JSON.stringify({"sku": data[index].sku, "color": data[index].color || null, "qty": newData[index].qty }));
        postData.append("role", "update_quantity");
          for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}update_cart.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state) {
                  set_qty(dispatch(set_qty({index: index, qty: newData[index].qty })))
                  setDoneLottie(true)
                  doneLottiePlayerRef.current.play();
                }else {
                  toast.error(responseData.msg) 
                  setLoading(false);
                }
                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
      }else {
        set_cart(dispatch(set_cart(newData)));
        localStorage.setItem("cart",JSON.stringify(newData))
      }
    }

  };
  const handleDecreasePuchaseQty = async (index) => {
    if (+data[index]["min_purchase_qty"] < +data[index].qty) {
      const newData = [...data]; // Create a shallow copy of the original array
      newData[index] = { ...newData[index], qty: newData[index].qty - 1 }; // Update the quantity of the specific item
      setData(newData); // Update the state with the new array
      const session = getCookie("session")
      if (session) {
        setLoading(true);
        setOverlay(true);
        let postData = new FormData();
        postData.append("session", session);
        postData.append("product", JSON.stringify({"sku": data[index].sku, "color": data[index].color || null, "qty": newData[index].qty }));
        postData.append("role", "update_quantity");
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}update_cart.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state) {
                  set_qty(dispatch(set_qty({index: index, qty: newData[index].qty })))
                  setDoneLottie(true)
                  doneLottiePlayerRef.current.play();
                }else {
                  toast.error(responseData.msg) 
                  setLoading(false);
                }
                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
      }else {
        set_cart(dispatch(set_cart(newData)));
        localStorage.setItem("cart",JSON.stringify(newData))
      }
    }
 
  };
  const handleDelete = async (index) => {
    const session = getCookie("session");
    if (session) {
      setLoading(true);
      setOverlay(true);
      let postData = new FormData();
      postData.append("session", session);
      postData.append("product", JSON.stringify({"sku": data[index].sku, "color": data[index].color || null}));
      postData.append("role", "delete");
      for (let i = 0; i < 100; i++) {
        try {
            let response = await fetch(`${api}update_cart.php`, {
                method: 'POST',
                body: postData
            });
            let responseData = await response.text();
            responseData = JSON.parse(responseData);
            setLoading(false);
            if (responseData.state) {
              del_from_cart(dispatch(del_from_cart(index)))
              setDelLottie(true)
              playerRef.current.play();
            }else {}
            return
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    }else {
      const newData = [...data]; // Create a shallow copy of the original array
      newData.splice(index, 1);
      setData(newData); 
      set_cart(dispatch(set_cart(newData)));
      localStorage.setItem("cart",JSON.stringify(newData))
    }
    setDelLottie(true)
    playerRef.current.play();
  }

  const handleEventPlayer = (e) => {
    if (e === 'complete') {
      setDelLottie(false); 
      setOverlay(false);
    };
  }

  const handleDoneLottieEventPlayer = (e) => {
    if (e === 'complete') {
      setDoneLottie(false); 
      setOverlay(false);
    };
  }

  const handleNavigate = () => {
    set_cart_state(dispatch(set_cart_state(false)))
    navigate("/checkout");
  }

  useEffect(()=>{
    setData(cart.items);
    let subtotal = 0;
    let discounts = 0;
    let delivery = 0;
    let length =  0;
    cart.items.map((ele)=>{
      subtotal += (+ele["price"] * ele["qty"])
      length += ele["qty"];
      // console.log(ele)
      if (ele.discounts)  {
        ele.discounts.forEach((discount)=>{
          if (ele["color"] === discount["color"]) {
            if (discount.type === "percent") {
              console.log(ele)
              discounts  +=  ( ( discount.amount) * +ele["price"]) * +ele["qty"]
            }else {
            }
          }
        })
      }
    })
    setCartDetails({...cartDetails, subtotal: subtotal, length: length, discounts: discounts})
  },[cart.items, cart.state]);
  
  return (
    <div className="relative cart">
      <div className={"fixed flex flex-col justify-between top-0 bottom-0 right-0  w-[95%] max-w-[400px]  bg-bw-02 transition-all duration-300 p-3 z-[200] " + (cart.state ? " cart-translate" : " translate-x-[100%]")}>
      <div className="flex justify-between">
          <span className="font-['cairo'] text-white text-lg font-bold">Shopping Cart</span>
          <img src={Close} alt="icon" className="cursor-pointer" onClick={()=>set_cart_state(dispatch(set_cart_state(false)))}></img>
        </div>
      <div className="flex-1 overflow-y-auto">

        <div className="flex flex-col mt-4 gap-4 overflow-y-auto">
            {
              data && data.map((ele,index)=>{
                const goldStars = [];
                const grayStars = [];
                // Populate goldStars and grayStars arrays
                for (let i = 0; i < Math.floor(ele.avg_rating); i++) {
                  goldStars.push(<img key={i} src={StarGold} alt="gold star" />);
                }
                // If the average rating is not an integer, add one gray star
                if (ele.avg_rating % 1 !== 0) {
                  grayStars.push(<img key="gray" src={StarGray} alt="gray star" />);
                }
                // Fll the remaining gray stars up to 4
                const remainingGrayStars = 5 - Math.ceil(ele.avg_rating);
                for (let i = 0; i < remainingGrayStars; i++) {
                  grayStars.push(
                    <img
                      key={i + Math.floor(ele.avg_rating)}
                      src={StarGray}
                      alt="gray star"
                    />
                  );
                }
                return <div className="flex items-center gap-2 rounded-lg p-2 bg-bw-03 " key={index}>
                    <div onClick={()=>navigate("/product/" +  ele.slug)}>
                     <img src={ele.img1} alt="product_image" className="w-[140px] rounded-[4px]" ></img>
                    </div>
                    <div className="gap-1 flex flex-col w-full">
                      {/* Review & Price */}
                      <div className="flex gap-[1px] justify-between items-center">
                        <div className="flex gap-1">
                          {goldStars}
                          {grayStars}
                          <span className="text-bw-06">
                            ({(ele.reviews && ele.reviews.length) || 0})
                          </span>
                        </div>
                        <span className=" font-['poppins'] text-white text-sm text-end font-semibold">{ele.price} LE</span>
                      </div>
                      {/* Name */}
                      <span className="text-sm leading-none	 text-white">{ele.title && ele.title.length > 40 ? ele.title.slice(0, 40) + "......" : ele.title}</span>
                      {/* delte & qty */}
                      <div className="flex justify-between items-center">
                        <div className="flex cursor-pointer group gap-1" onClick={()=>{handleDelete(index)}}>
                          <img src={TrashGray} alt="icon" className="group-hover:hidden"></img>
                          <img src={TrashRed} alt="icon" className="cursor-pointer hidden group-hover:block"></img>
                          <span className="font-['cairo'] text-bw-04 group-hover:text-white">Delete</span>
                        </div>
                        <div className="select-none flex items-center gap-2 my-2">
                            <div className="flex gap-2">
                            <div className="w-8 h-8  bg-bw-02 border-[1.5px] border-bw-04 rounded-l-lg flex items-center justify-center" onClick={()=>handleDecreasePuchaseQty(index)}>
                                {(
                                    +data[index].qty <= +data[index].min_purchase_qty
                                ) ? (
                                    <span className="text-bw-03 font-['kensmark-03'] text-2xl">-</span>
                                ) : (
                                    <span className="text-gradient font-['kensmark-03'] text-2xl cursor-pointer">-</span>
                                )}
                            </div>

                            <div className="w-8 h-8  text-white font-['kensmark-03'] bg-bw-02 border-[1.5px] border-bw-04 flex items-center justify-center" >
                                {data[index].qty}
                            </div>
                            <div
                                className="w-8 h-8  bg-bw-02 border-[1.5px] border-bw-04 rounded-r-lg flex items-center justify-center"
                                onClick={()=>handleIncreasePuchaseQty(index)}
                            >
                              {(
                                +data[index].qty >= +data[index].max_purchase_qty
                                ) ? (
                                    <span className="text-bw-03 font-['kensmark-03'] text-2xl">+</span>
                                ) : (
                                    <span className="cursor-pointer text-gradient font-['kensmark-03'] text-2xl">+</span>
                              )}
                  </div>
                </div>
                         </div>
                      </div>
                    </div>
                </div>
              })
            }
           
            
        </div>
      </div>
      <div className="flex flex-col gap-1 mb-2">
              <div className="flex justify-between items-center">
                <span className="text-xl text-white font-semibold">Subtotal ({cartDetails.length} items)</span>
                <span className="text-xl text-white font-medium">{cartDetails.subtotal} LE</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xl text-white font-semibold">Discounts</span>
                <span className="text-xl text-white  font-medium">-{cartDetails.discounts} LE</span>
              </div>
              <div className="h-px bg-bw-04 "></div>
              <div className="flex justify-between items-center">
                <span className="text-xl text-white font-semibold">Total</span>
                <span className="text-xl text-white  font-medium">{(+cartDetails.subtotal - +cartDetails.discounts)} LE</span>
              </div>
            
                <button className="bg-blue text-white font-bold text-xl py-3 rounded-lg mt-5" onClick={()=>handleNavigate()}> Proceed to Checkout</button>
             
      </div>
        {
          overlay && <Overlay></Overlay>
        }
       <div className=" absolute z-50 w-48 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
           <Player
              src={DelLottie}
              className="player"
              speed={1}
              style={delLottie ? {  width: '180px' } : {width: '0px'}}
              ref={playerRef}
              onEvent={(e)=>handleEventPlayer(e)}
          />
          <Player
              src={DoneLottie}
              className="player"
              speed={1}
              style={doneLottie ? {  width: '180px' } : {width: '0px'}}
              ref={doneLottiePlayerRef}
              onEvent={(e)=>handleDoneLottieEventPlayer(e)}
          />
        </div>
        {loading && <Loader></Loader> }
      </div>
      
      {
    
    cart.state && <div onClick={()=>set_cart_state(dispatch(set_cart_state(false)))}><Overlay></Overlay></div>
      } 
    </div>
    );
  }
  

export default Cart;
