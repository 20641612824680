


function Loader() {
    return (
        <div class="lds-spinner z-10 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
}

export default Loader;
