import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    state: false
  },
  reducers: {
    set_cart: (state, action) => {
      state.items = action.payload;
    },
    // On, off
    set_cart_state: (state, action) => {
      state.state = action.payload;
    },
    add_to_cart: (state, action) => {
      const { sku, color } = action.payload;
      let is_found = false;
      let found_index = 0;
      state.items.forEach((ele,ind) => {
        if (ele.sku === sku) {
          if (action.payload.colors ) {
            if (ele.color === color) {
              is_found = true
              found_index = ind
            }
          }else {
            is_found = true
            found_index = ind
          }
        }
      });
    if (!is_found)  {
        state.items = [...state.items, action.payload]
      }else {
        const newData = [...state.items]
        newData[found_index] = {...newData[found_index], qty: newData[found_index]["qty"] + action.payload.qty}
        state.items = newData;
      }

    },
    del_from_cart: (state, action) => {
      state.items.splice(action.payload, 1)
    },
    set_qty: (state, action) => {
      const {index , qty} = action.payload;
      state.items[index]["qty"] = qty;
    }
    
  }
});

// Action creators are generated for each case reducer function
export const { set_cart , set_cart_state, add_to_cart, del_from_cart , set_qty} = cartSlice.actions;

export default cartSlice.reducer;
