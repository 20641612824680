import { useEffect, useState } from "react";

import Header from "../components/Header";
import Nav from "../components/Nav";
import Loader from "../components/Loader";
import api from "../statics/api.js";

import Overlay from "../components/Overlay";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCookie } from "../App.js";
import Cart from "../components/cart.compontent.jsx";

import SearchInput from "../components/search-input.component.jsx";
import MobileMenu from "../components/menu.component.jsx";
import Footer from "../components/footer.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { set_cart } from "../reducers/cart.js";
import { set_username } from "../reducers/username.js";


function PointsPage() {

    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);
    const [points, setPoints] = useState([]);
    const [totalPoints, setTotalPoints]  = useState(0);

    const loadData = async () => {
        const session = getCookie("session");
        if (!session) {
          return;
        }
    
        let formData = new FormData();
        formData.append("session", session);
    
        try {
          const response = await fetch(`${api}view_my_points.php`, {
            method: "POST",
            body: formData,
          });
    
          if (response.ok) {
            const responseText = await response.text();
            const responseData = JSON.parse(responseText);
    
            if (responseData.res.state) {
                console.log(response.points)
            setPoints(responseData.points);
             const totalPoints = responseData.points.reduce((acc, ele) => acc + ele.points, 0); // Calculates total points
             setTotalPoints(totalPoints)

              if (responseData.res.msg === "New session") {
                const expiryDate = new Date(responseData.exp);
                document.cookie = `session=${
                  responseData.new_session
                }; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                localStorage.setItem("fullname", responseData.res.username);
                dispatch(set_username(localStorage.getItem("fullname")));
              }
              return;
            } else {
              // Delete Session
              toast.error("Please login again.")
              await new Promise((resolve) => setTimeout(resolve, 2000));
              document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict`;
              localStorage.setItem("cart", "[]");
              dispatch(set_cart([]));
              window.location.reload();
            }
          } else {
            console.error("Failed to fetch:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
    };
    

    useEffect(() => {
        window.scroll(0, 0)
        loadData();
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname
        })
    }, []);

    const handleSearchToggle = () => {
        setIsSearching((prev) => !prev);
    };


    return (
        <>
            <Nav></Nav>
            <Header onSearchClick={handleSearchToggle}></Header>
            {
                <Helmet>
                    <title>PuZzLe Technology - My Points</title>
                    <meta name="robots" content="noindex, nofollow"></meta>
                </Helmet>

            }

            <main className="relative overflow-x-auto px-mobile md:px-tablet xl:px-pc py-6 md:py-10 min-h-96  2xl:mx-auto">
                <h1 className="text-white font-bold mb-4 text-2xl">My Points</h1>
                <div className="flex flex-wrap xl:flex-nowrap  gap-6">

                    <aside className="bg-[#1B0351] text-white font-['Montserrat']  w-full xl:w-56 p-4 font-semibold rounded shadow-points max-h-fit">
                        <h3 className="mb-2">Available Points</h3>
                        <span className="font-['kensmark-03'] text-4xl">{totalPoints} <span className="text-2xl">PTS</span></span>
                    </aside>

                    <div className="bg-bw-02 rounded-lg p-6 text-white w-full">
                        <h3 className="font-semibold">History of your points</h3>
                        <table className="w-full mt-4 bg-black">
                            <thead>
                                <tr>
                                    <th className="font-bold border-[1px] border-white p-2 text-xs w-7">N.O</th>
                                    <th className="font-bold border-[1px] border-white p-2 text-xs text-left">Number of points</th>
                                    <th className="font-bold border-[1px] border-white p-2 text-xs text-left">Date</th>
                                    <th className="font-bold border-[1px] border-white p-2 text-xs text-left">How you get points</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    points.map((ele,index)=>{
                                        return <tr>
                                            <td className="border-[1px] border-white p-2 text-xs text-left">{index +1 }</td>
                                            <td className="border-[1px] border-white p-2 text-xs text-left">{ele.points}</td>
                                            <td className="border-[1px] border-white p-2 text-xs text-left">{ele.gained_date}</td>
                                            <td className="border-[1px] border-white p-2 text-xs text-left">{ele.reason}</td>
                                        </tr>
                                    })
                                }
                
                            </tbody>
                        </table>
                    </div>

                </div>
            </main>


            <BottomNav currentPage={"/profile"}></BottomNav>
            <Footer></Footer>
            <Cart></Cart>
            <ToastContainer></ToastContainer>
            <MobileMenu></MobileMenu>

            {isSearching ? (
                <>
                    <SearchInput></SearchInput>
                    <div onClick={() => setIsSearching(false)}>
                        <Overlay></Overlay>
                    </div>
                </>
            ) : null}

            {loading ? (
                <>
                    <Overlay></Overlay> <Loader> </Loader>
                </>
            ) : null}
        </>
    );
}

export default PointsPage;
