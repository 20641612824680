import Nav from "../components/Nav";
import SearchInput from "../components/search-input.component";
import Overlay from "../components/Overlay";
import MobileMenu from "../components/menu.component";
import BottomNav from "../components/bottom-nav.component";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/footer.component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CartIcon from "../assets/icons/cart.svg";
import BreadCrump from "../components/BreadCrump.component";
import Cart from "../components/cart.compontent";
import api from "../statics/api";
import { getCookie } from "../App";
import { useDispatch } from "react-redux";
import { set_username } from "../reducers/username";
import { set_cart } from "../reducers/cart";

function MyOrders() {
  const [isSearching, setIsSearching] = useState(false);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breadcrumbItems = [
    { name: "HOME", to: "/" },
    { name: "My Account", to: "/my-account" },
    { name: "My Orders", to: "/my-account/orders" },
  ];

  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };

  // Get My Data
  const loadData = async () => {
    const session = getCookie("session");
    if (!session) {
      return;
    }

    let formData = new FormData();
    formData.append("session", session);

    try {
      const response = await fetch(`${api}get_my_orders.php`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseText = await response.text();
        const responseData = JSON.parse(responseText);

        if (responseData.res.state) {
          console.log(responseData.orders);
          setOrders(responseData.orders);
          if (responseData.res.msg === "New session") {
            const expiryDate = new Date(responseData.exp);
            document.cookie = `session=${
              responseData.new_session
            }; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
            localStorage.setItem("fullname", responseData.res.username);
            dispatch(set_username(localStorage.getItem("fullname")));
          }
          return;
        } else {
          // Delete Session
          // toast.error("Please login again.")
          await new Promise((resolve) => setTimeout(resolve, 2000));
          document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict`;
          localStorage.setItem("cart", "[]");
          dispatch(set_cart([]));
          window.location.reload();
        }
      } else {
        console.error("Failed to fetch:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>PuZzLe Technology | My Orders</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Nav />
      <Header onSearchClick={handleSearchClick} />
      <BreadCrump items={breadcrumbItems} />

      <div class="relative overflow-x-auto px-mobile md:px-tablet xl:px-pc py-6 md:py-10 min-h-96">
        <h1 className="font-['kensmark-03'] text-white text-xl ">My Orders</h1>
        <table class="w-full text-md text-left rtl:text-right  text-gray-400 mt-4 ">
          <thead class="text-xs  uppercase  bg-bw-03 text-white ">
            <tr className="">
              <th scope="col" class="px-6 py-3">
                ORDER
              </th>
              <th scope="col" class="px-6 py-3">
                DATE
              </th>
              <th scope="col" class="px-6 py-3">
                STATUS
              </th>
              <th scope="col" class="px-6 py-3">
                Shipping
              </th>
              <th scope="col" class="px-6 py-3">
                TOTAL
              </th>
              <th scope="col" class="px-6 py-3 w-24">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => {
              return (
                <tr key={index} className=" border-b bg-bw-02 border-black">
                  <th
                    scope="row"
                    class="px-6 py-4 text-sm md:text-base font-medium  whitespace-nowrap text-white"
                  >
                    #{order.order_code}
                  </th>
                  <td class="px-6 py-4 text-sm md:text-base ">{order.created_at}</td>
                  <td className="px-6 py-4">
                    {order.order_state === "Confirmed" && (
                      <span className="bg-[#4caf50] text-white p-2 px-4 rounded-3xl text-[10px] md:text-base">
                        Confirmed
                      </span>
                    )}
                    {order.order_state === "Pending" && (
                      <span className="bg-[#ff9800] text-white p-2 px-4 rounded-3xl">
                        Pending
                      </span>
                    )}
                    {order.order_state === "Shipped" && (
                      <span className="bg-[#03a9f4] text-white p-2 px-4 rounded-3xl">
                        Shipped
                      </span>
                    )}
                    {order.order_state === "Delivered" && (
                      <span className="bg-[#8bc34a] text-white p-2 px-4 rounded-3xl">
                        Delivered
                      </span>
                    )}
                    {order.order_state === "Cancelled" && (
                      <span className="bg-[#f44336] text-white p-2 px-4 rounded-3xl">
                        Cancelled
                      </span>
                    )}
                    {order.order_state === "Returned" && (
                      <span className="bg-[#9c27b0] text-white p-2 px-4 rounded-3xl">
                        Returned
                      </span>
                    )}
                  </td>
                  <td class="px-6 py-4 text-[12px] md:text-base">{order.shipping} EGP</td>

                  <td class="px-6 py-4 text-[12px] md:text-base">
                    {(order.order_subtotal -
                      order.coupon_discounts -
                      order.products_discounts -
                      -order.shipping).toLocaleString() + " EGP"}
                  </td>

                <Link to= {order.order_code}>
                <td class="px-6 py-4 text-[12px]  text-blue font-['kensmark-03'] text-sm cursor-pointer underline ">
                    View
                  </td>
                </Link>
                  
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Cart></Cart>
      <Footer />
      <MobileMenu />
      <BottomNav currentPage="" />
    </>
  );
}

export default MyOrders;
