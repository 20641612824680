import { createSlice } from '@reduxjs/toolkit';

export const usernameSlice = createSlice({
  name: 'username',
  initialState: {
    value: ""
  },
  reducers: {
    set_username: (state, action) => {
      state.value = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { set_username } = usernameSlice.actions;

export default usernameSlice.reducer;
