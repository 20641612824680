import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import Facebook from "../assets/icons/facebook.svg";
import Whatsapp from "../assets/icons/whatsapp.svg";
import Instagram from "../assets/icons/instagram.svg";
import Location from "../assets/icons/location.svg";
import Phone from "../assets/icons/phone.svg";
import Email from "../assets/icons/email.svg";
import Send from "../assets/icons/send.svg";
import api from "../statics/api";
import { toast } from "react-toastify";




function Footer() {
  const [email, setEmail] = useState("");
  
  const subscribe = async () => {
    let postData = new FormData();

    if (email.trim().length === 0  ) {
      toast.error("You must write the email first")
      return
    }
  
    postData.append('email', email);;
  
    try {
      let response = await fetch(`${api}subscribe.php`, {
        method: 'POST',
        body: postData
      });
  
      let responseData = await response.json();  // Directly parse JSON
  
      if (responseData.state) {
        toast.success(responseData.msg);
      } else {
        toast.error(responseData.msg);
        return
      }
    } catch (error) {}
  }
    
 
  return (
    <div>
      <footer className="mt-10 flex flex-wrap items-center justify-between p-10 lg:px-[40px] lg:py-[60px] 2xl:px-[120px] 2xl:py-[80px] gap-8 sm:gap-6 font-['montserrat'] text-white bg-bw-02">
        <div className="flex flex-col gap-4">
          <img src={Logo} alt="logo" className="lg:w-16 2xl:w-[88px]" />
          <div className="flex flex-col">
            <span><b>PuZzLe</b> TECH</span>
            <span>COMPUTER STORE</span>
          </div>
          <div className="flex gap-2">
            <Link to="https://www.facebook.com/PuZzLeTechnology">
              <img className="w-[24px]" src={Facebook} alt="logo" />
            </Link>
            <Link to="https://wa.me/+201068532324">
              <img className="w-[24x]" src={Whatsapp} alt="logo" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Link to=""><b>My Account</b></Link>
          <div className="text-white flex flex-col">
            <Link to="/login">Login</Link>
            <Link to="/sign-up">Sign Up</Link>
            <Link to="/wishlist">My wishlist</Link>
            {/* <Link to="/ca">My Cart</Link> */}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <b>Contact Information</b>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <img src={Location} alt="icon" />
              <span>Al-Boustan Mall, 9th floor Bab Al-Louq, Cairo</span>
            </div>
            <div className="flex gap-2">
              <img src={Phone} alt="icon" />
              <span>+201068532324</span>
            </div>
            <div className="flex gap-2">
              <img src={Email} alt="icon" />
              <span>info@puzzle-technology.com</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <span><b>Subscribe here</b></span>
          <div className="flex">
            <input onChange={(e)=>{setEmail(e.target.value)}} className="flex-1 h-10 pl-3 text-black outline-none border-none rounded-l-lg" placeholder="Write your email here" />
            <button  onClick={subscribe}  className="save-gradient w-[72px] flex justify-center items-center rounded-r-lg">
              <img src={Send} alt="icon" className="block mx-auto" />
            </button>
          </div>
        </div>
      </footer>
      <div className="flex justify-center pb-[70px]  text-center px-[40px] py-4 md:pb-4 2xl:px-[120px] 2xl:py-6 text-white font-medium">
        © Puzzle Technology eCommerce 2024
      </div>
    </div>
  );
}

export default Footer;
