import React from "react";
import Profile from "../assets/icons/profile.svg";
import Facebook from "../assets/icons/facebook.svg";
import Whatsapp from "../assets/icons/whatsapp.svg";
import Logout from "../assets/icons/logout.svg";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


function Nav() {
  const username = useSelector((state) => state.username.value);
  const handleLogOut = () => {
    document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location = "/"
  }
  return (
    <nav className="flex bg-black justify-end items-center py-2 px-mobile md:px-tablet xl:px-pc  gap-[8px]">
      <Link to="https://wa.me/+201068532324">
        <img
            src={Whatsapp}
            className="w-6 hover:scale-105 transition duration-300 cursor-pointer"
            alt="Whatsapp Icon"
          />
      </Link>
      <Link to="https://www.facebook.com/PuZzLeTechnology">
      <img
        src={Facebook}
        className="w-6 hover:scale-105 transition duration-300 cursor-pointer"
        alt="Facebook Icon"
      />
      </Link>

      <span></span>
      <span className="text-white border w-[0px] h-[24px]"></span>
      <span></span>


      {username ? (
        <>
        <Link to={"/my-account"}>

        <img
        src={Profile}
        className="w-6 hover:scale-105 transition duration-300 cursor-pointer"
        alt="Profile Icon"
      />
      
        </Link>

          <span className="font-['kensmark-03']  text-gradient ">
            {username}
          </span>
          <img
            src={Logout}
            onClick={handleLogOut}
            className="hover:scale-105 transition duration-300 cursor-pointer"
            alt="logout_icon"
          />
        </>
      ) : (
        <>
         <Link to={"/login"} className="flex gap-2">
              <img
        src={Profile}
        className="w-6 hover:scale-105 transition duration-300 cursor-pointer"
        alt="Profile Icon"
      />
       
          <span className="text-white font-['kensmark-03'] select-none cursor-pointer hover:text-gradient ">
            LOGIN
          </span>
        </Link>
        </>


      )}
    </nav>
  );
}

export default Nav;
