import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../components/Header";
import Nav from "../components/Nav";
import Footer from "../components/footer.component";
import Loader from "../components/Loader";
import Cart from "../components/cart.compontent";
import SearchInput from "../components/search-input.component";
import Overlay from "../components/Overlay";
import BottomNav from "../components/bottom-nav.component";
import FormInput from "../components/FormInput";
import { set_username } from "../reducers/username";
import cart, { set_cart } from "../reducers/cart";
import api from "../statics/api";
import { useNavigate } from "react-router-dom";
import CartIcon from "../assets/icons/cart.svg"
import OrderCompleted from "../assets/images/order_completed.svg"
import { Helmet } from "react-helmet";


function CheckoutPage() {
    const [isSearching, setIsSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shippingData, setShippingData] = useState({
        first_name: "",
        last_name: "",
        company_name: "",
        country: "",
        street_address: "",
        apartment: "",
        phone_number: "",
        city: "",
        state: ""
    });
    const [paymentMethod, setPaymentMethod] = useState("");
    // Set Coupon 
    const [coupon, setCoupon] = useState("");
    const [cartDetails, setCartDetails] = useState({
        subtotal: 0,
        discounts: 0,
        delivery: 0,
        length: 0,
        couponDiscounts: 0,
        coupons: [],
    });
    const [orderCompeleted, setOrderCompleted] = useState(false);
    const [orderCode, setOrderCode] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // Points
    const [points, setPoints] = useState([]);
    const [totalPoints, setTotalPoints]  = useState(null);
    const [usedPoints, setUsedPoints] = useState(0);
    const [usePointsWidget, setUsePointsWidgetState] = useState(false)


    const handleUsePoints = () => {
        if (usedPoints > 0 && usedPoints <= totalPoints ) {

        }else {
            toast.error("Please write a valid points value")
        }
    }

    const skus = data.cart.items.map((ele)=>{
        return ele["sku"]
    })

    const getCookie = (cookieName) => {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        return false;
    }
    const handleInputChange = (type, value) => {
        setShippingData({...shippingData, [type]: value});
    }
    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    }
    const handleCheckCoupon = async () => {
        if (coupon){
            if (cartDetails.coupons.includes(coupon)) {
                toast.error("Coupon is used!");
                return;
            }
            setLoading(true);
            let postData = new FormData();
            postData.append('coupon', coupon);
      
            for (let i = 0; i < 100; i++) {
                try {
                    let response = await fetch(`${api}check_coupon.php`, {
                        method: 'POST',
                        body: postData
                    });
                    let responseData = await response.text();
                    responseData = JSON.parse(responseData);
                    setLoading(false);
            
                    // Coupon is found
                    if (responseData.state) {
                        // Loop on skus if found so add the coupon discount
                        let is_found = false
                        skus.forEach((sku)=>{
                            if (responseData["coupon_skus"].includes(sku) && !cartDetails.coupons.includes(coupon)) {
                                is_found = true
                                const newCoupons = [...cartDetails.coupons]
                                newCoupons.push(coupon);
                                setCartDetails({...cartDetails, couponDiscounts: cartDetails.couponDiscounts + +responseData["coupon_value"], coupons: newCoupons })
                            }
                            return
                        })
                        if (!is_found) toast.error("The coupon gives no discounts for your cart items")
                        return
                    }
                    else {
                        toast.error("Coupon is not found") 
                        return                   
                    }
                } catch (error) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
       
        }else {
            toast.error("Enter the coupon first")
        }

    }
    const checkOrderDetails = async () => {
        const session = getCookie("session");
        if (!session) {
            toast.error("Please login first");
            return 0
        }
        if (shippingData.first_name.trim().length === 0 || shippingData.last_name.trim().length === 0  || shippingData.apartment.trim().length === 0 || shippingData.city.trim().length === 0 || shippingData.company_name.trim().length === 0 || shippingData.country.trim().length === 0 || shippingData.phone_number.trim().length === 0 ||  shippingData.state.trim().length === 0 || shippingData.street_address === 0 ) {
            toast.error("Please fill in shipping data first");
            return 0
        }
        if (paymentMethod.trim() === 0 ) {

        }
        return 1
    }
    const completeOrder = async () => {
        const is_completed = checkOrderDetails();
        if (!is_completed) return
        
        let postData = new FormData();
        const session = getCookie("session");
        const orderDetails = {...shippingData, payment_method: paymentMethod}

        postData.append('session', session);
        postData.append('coupon_codes', JSON.stringify(cartDetails.coupons));
        postData.append('order_details', JSON.stringify(orderDetails));
        postData.append('points', usedPoints);
        const toastId = toast.loading('Completing Your Order...');

        for (let i = 0; i < 10; i++) {
            try {
            
                let response = await fetch(`${api}complete_order.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state) {
                    setOrderCompleted(true)
                    setOrderCode(responseData.order_id)
                    toast.update(toastId, {
                        render: 'Your order has been placed successfully.',
                        type: 'success',
                        isLoading: false,
                        autoClose: 1000,
                      });
                    return
                }else {
                    toast.update(toastId, {
                        render: responseData.msgs.join(" - "),
                        type: 'eror',
                        isLoading: false,
                        autoClose: 5000,
                      });
                    return
                }
                
                return
            } catch (error) {
                console.log(error)
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    }
    const loadPoints = async () => {
        const session = getCookie("session");
        if (!session) {
          return;
        }
    
        let formData = new FormData();
        formData.append("session", session);
    
        try {
          const response = await fetch(`${api}view_my_points.php`, {
            method: "POST",
            body: formData,
          });
    
          if (response.ok) {
            const responseText = await response.text();
            const responseData = JSON.parse(responseText);
    
            if (responseData.res.state) {
                console.log(response.points)
            setPoints(responseData.points);
             const totalPoints = responseData.points.reduce((acc, ele) => acc + ele.points, 0); // Calculates total points
             setTotalPoints(totalPoints)

              if (responseData.res.msg === "New session") {
                const expiryDate = new Date(responseData.exp);
                document.cookie = `session=${
                  responseData.new_session
                }; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                localStorage.setItem("fullname", responseData.res.username);
                dispatch(set_username(localStorage.getItem("fullname")));
              }
              return;
            } else {
              // Delete Session
              toast.error("Please login again.")
              await new Promise((resolve) => setTimeout(resolve, 2000));
              document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict`;
              localStorage.setItem("cart", "[]");
              dispatch(set_cart([]));
              window.location.reload();
            }
          } else {
            console.error("Failed to fetch:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
    }

    useEffect(() => {
        const session = getCookie("session");
        if (session) {
            setIsLoggedIn(true)
            let subtotal = 0;
            let discounts = 0;
            let delivery = 0;
            let length =  0;
            data.cart.items.map((ele)=>{
            subtotal += (+ele["price"] * ele["qty"])
            length += ele["qty"];
            if (ele.discounts)  {
                ele.discounts.forEach((discount)=>{
                if (ele["color"] === discount["color"]) {
                    if (discount.type === "percent") {
                        discounts  +=  ( ( discount.amount) * +ele["price"]) * +ele["qty"]
                    }else {
                    }
                }
                })
            }
            })
            setCartDetails({...cartDetails, subtotal: subtotal, length: length, discounts: discounts})

        }else {
            toast.error("Please login first.");
            navigate("/login")
        }
        // handleRequest();
    }, [data]); // This useEffect will run only once when component mounts

    useEffect(()=>{
        loadPoints()
    },[]);

    return (
        <>
            <Nav></Nav>
            <Header></Header>
            <Helmet>
                <title>PuZzLe Technology | Checkout</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {
                data.cart && data.cart.items.length && !orderCompeleted &&  
                <main className="px-mobile md:px-tablet xl:px-pc py-6 md:py-10 min-h-96">
                <div className="flex-col md:flex md:flex-row-reverse gap-8">
                    <div className="bg-bw-02 py-4 w-full md:w-fit h-fit px-2 font-['cairo'] rounded-2xl text-white flex flex-col gap-2">
                        <div className="flex justify-between">
                            <span>Subtotal ({data.cart.items.length} items)</span>
                            <span>{cartDetails.subtotal} LE</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Discounts</span>
                            <span>{cartDetails.discounts} LE</span>
                        </div>
                        {/* <div className="flex justify-between">
                            <span>Delivery</span>
                            <span>120 LE</span>
                        </div> */}
                         <div className="flex justify-between">
                            <span>Coupon Discounts</span>
                            <span>{cartDetails.couponDiscounts} LE</span>
                        </div>

                        <div className="flex justify-between">
                            <span>Points Discounts</span>
                            <span>{usedPoints / 10} LE</span>
                        </div>
                        <div className="h-px bg-bw-04"></div>
                        <div className="flex justify-between">
                            <span>Total</span>
                            <span>{cartDetails.subtotal - cartDetails.discounts - cartDetails.couponDiscounts - (usedPoints/10)} LE</span>
                        </div>
                        <button
                        onClick={completeOrder}
                        className={`mt-4 bg-blue text-white text-sm rounded-lg h-8 font-bold font-['cairo'] ${isLoggedIn ? '' : 'opacity-50 cursor-not-allowed'}`}
                    >
                        Proceed to Checkout
                    </button>

                        
                        <span>Have a promo code ?</span>
                        <div className="flex ">
                            <input className="flex-1 bg-bw-03 h-10 pl-3 text-white outline-none border-none rounded-l-lg" placeholder="Enter promo code" onChange={(ele)=>setCoupon(ele.target.value)} />
                            <button onClick={handleCheckCoupon} className="save-gradient w-[72px] flex justify-center items-center rounded-r-lg">
                            Apply
                            </button>
                        </div>
                        {
                            totalPoints && totalPoints > 0 &&      <aside className="bg-[#1B0351] text-white font-['Montserrat']  w-full p-4 font-semibold rounded shadow-points max-h-fit">
                            <h3 className="mb-2">Available Points</h3>
                            <span className="font-['kensmark-03'] text-4xl">{totalPoints} <span className="text-2xl">PTS</span></span>
                            <button className="rounded-sm p-2 bg-[#270A76] text-[#1C84DC] block w-full mt-2" onClick={()=>setUsePointsWidgetState(!usePointsWidget)}> 
                                Use Points
                            </button>
                            {
                                usePointsWidget &&   <div className="flex gap-2  mt-2 items-center">
                                <input placeholder="Write number of points ..." className="bg-[#090023] rounded-sm p-2 text-xs text-bw-06 border-none outline-none flex-1" type="number" value={usedPoints} onChange={(e)=>{if (+e.target.value > totalPoints){
                                    setUsedPoints(totalPoints);return} setUsedPoints(e.target.value)}}></input>
                            </div>
                            }
                          
                        </aside>
                        }
                    </div>


                    <div className="font-['cairo']  flex-1">
                        <h1 className="text-2xl text-white ">Shipping Address</h1>
                        <div className="mt-4 md:mt-7">
                            <div className="flex gap-2 flex-col md:flex-row">
                                <div className="flex-1">
                                    <FormInput  placeHolder="first name" img={null} type={"text"} name="First Name" onChange={(ele)=>handleInputChange("first_name",ele.target.value.trim())}></FormInput>
                                </div>
                                <div className="flex-1"> 
                                    <FormInput placeHolder="last name" img={null} type={"text"} name="Last Name" onChange={(ele)=>handleInputChange("last_name",ele.target.value.trim())}></FormInput>
                                </div>
                            </div>
                            <div className="flex gap-2 flex-col md:flex-row">
                                <div className="flex-1">
                                    <FormInput placeHolder="company name" img={null} type={"text"} name="Company Name" onChange={(ele)=>handleInputChange("company_name",ele.target.value.trim())}></FormInput>
                                </div>
                                <div className="flex-1">
                                    <FormInput placeHolder="country" img={null} type={"text"} name="Country / Region" onChange={(ele)=>handleInputChange("country",ele.target.value.trim())}></FormInput>
                                </div>
                            </div>
                            <div className="flex gap-2 flex-col md:flex-row">
                                <div className="flex-1">
                                    <FormInput placeHolder="street address" img={null} type={"text"} name="Street Address" onChange={(ele)=>handleInputChange("street_address",ele.target.value.trim())}></FormInput>
                                </div>
                                <div className="flex-1">
                                    <FormInput placeHolder="apartment" img={null} type={"text"} name="Apartment" onChange={(ele)=>handleInputChange("apartment",ele.target.value.trim())}></FormInput>
                                </div>
                            </div>
                            <div className="flex gap-2 flex-col md:flex-row">
                                <div className="flex-1">
                                    <FormInput placeHolder="phone number" img={null} type={"text"} name="Phone Number" onChange={(ele)=>handleInputChange("phone_number",ele.target.value.trim())}></FormInput>
                                </div>
                                <div className="flex-1">
                                    <FormInput placeHolder="city" img={null} type={"text"} name="City" onChange={(ele)=>handleInputChange("city",ele.target.value.trim())}></FormInput>
                                </div>
                            </div>
                            <div className="flex gap-2 flex-col md:flex-row">
                                <div className="flex-1">
                                    <FormInput placeHolder="state" img={null} type={"text"} name="State" onChange={(ele)=>handleInputChange("state",ele.target.value.trim())}></FormInput>
                                </div>
                                <div className="flex-1"></div>
                            </div>
                            <div className="flex gap-2">
                                <div className="flex-1 hidden md:block"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 text-white">
                    <h1 className="text-2xl">Payment Method</h1>
                    <div className="ml-1 flex gap-2 flex-col">
                        <div className="flex gap-2 mt-4">
                            <input
                                type="radio"
                                id="cash"
                                name="payment_method"
                                value="Cash on Delivery"
                                checked={paymentMethod === "Cash On Delivery"}
                                onChange={() => handlePaymentMethodChange("Cash On Delivery")}
                            />
                            <label for="cash">Cash on Delivery</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                id="vod"
                                name="payment_method"
                                value="Vod Cash"
                                checked={paymentMethod === "Vod Cash"}
                                onChange={() => handlePaymentMethodChange("Vod Cash")}
                            />
                            <label for="vod">Vodafone Cash</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                id="insta"
                                name="payment_method"
                                value="Insta Pay"
                                checked={paymentMethod === "Insta Pay"}
                                onChange={() => handlePaymentMethodChange("Insta Pay")}
                            />
                            <label for="insta">Insta Pay</label>
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="radio"
                                id="credit"
                                name="payment_method"
                                value="Credit / Debit Card (Available soon)"
                                disabled
                            />
                            <label for="credit" className="cursor-not-allowed">Credit / Debit Card (Available soon)</label>
                        </div>
                    </div>
                </div>

                </main>
            }
            {
                data.cart && data.cart.items.length === 0 && !orderCompeleted && <div className=" mx-auto flex flex-col gap-2 items-center my-32">
                <img src={CartIcon} className="w-40" alt="icon"></img>
                <h1 className="mx-auto text-bw-03 text-2xl">Your cart is empty</h1>
                </div>
            }
            {
                orderCompeleted && 
                <div className="mt-8 w-[90%] max-w-96 mx-auto bg-bw-02 rounded-lg py-10 md:px-10 flex flex-col items-center text-white font-['cairo'] gap-12 text-2xl">
                    <img src={OrderCompleted} alt="img"></img>
                    <div className="flex flex-col gap-4 items-center">
                        <span>Order (#{orderCode})</span>
                        <span className="font-bold text-center">Your order was placed successful</span>
                    </div>
                    <button className="bg-blue font-bold text-lg rounded-lg py-3 px-4 w-full" onClick={()=>navigate("/")}>Back to home</button>
                </div>
            }
            
          
           

            <BottomNav currentPage={"checkout"}></BottomNav>
            <Footer></Footer>
            {loading?
            <>
                <Loader></Loader>
                <Overlay></Overlay>
            </>
                
                : null
            }
            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }
           <ToastContainer></ToastContainer>
           <Cart></Cart>
        </>   
    )
}

export default CheckoutPage;

// {
//     "first_name": "ELEO",
//     "last_name": "ALI",
//     "company_name": "PuZzLe Tech",
//     "country": "egypt",
//     "street_address": "16 nh",
//     "apartment": "16 BLOCk",
//     "phone_number": "01288729838",
//     "city": "Giza",
//     "state": "v",
//     "payment_method": "Vod Cash"
//   }