import Header from "../components/Header.jsx";
import Nav from "../components/Nav.jsx";
import { useEffect, useState } from "react";
import  api from "../statics/api.js"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader.jsx";
import Ovarlay from "../components/Overlay.jsx";
import { useDispatch } from "react-redux";
import SearchInput from "../components/search-input.component.jsx";
import Overlay from "../components/Overlay.jsx";
import ProductVertical from "../components/product-vertical.component.jsx";
import Cart from "../components/cart.compontent.jsx";
import MobileMenu from "../components/menu.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";
import Footer from "../components/footer.component.jsx";
import { Helmet } from "react-helmet";

function TodayBestDeals() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const dispatch = useDispatch();

    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };

    const handleRequest = async () => {
        for (let i = 0; i < 100; i++) {
            try {
            
                let response = await fetch(`${api}today_best_deals.php`, {
                    method: 'GET',
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                if (responseData.state) {
                    setProducts(responseData["products"]);
                }
                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    }

    const ProductsSkeleton = () => {
        return <div className="mt-10 flex flex-col gap-6">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-2 ">
            {
                Array.from({ length: 16 }).map((___, index)=> {
                    return <div
                    className={"min-w-[150px] animate-pulse  p-2 bg-bw-02 flex relative flex-col gap-2 rounded-lg flex-1 " }
                >
                    <div role="status" class="max-w-sm ">
                        <div class="h-48 bg-gray-200 rounded-lg mb-4"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[360px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[330px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full  max-w-[100px] mb-2.5"></div>
                    </div>
                </div>
                }) }
                
            </div>
        </div>
    }
    useEffect(()=>{
        window.scroll(0,0)
        handleRequest()
    },[])
    return (
        <>
            <Nav></Nav>
            <Header onSearchClick={handleSearchClick}></Header>
            <Helmet>
                <title>PuZzLe Technology - Today Best Deals</title>
                <meta name="description" content="Discover unbeatable prices on the latest tech with Today’s Best Deals. Explore exclusive discounts on top-rated gadgets, computer components, and gaming gear to upgrade your setup at the best prices"></meta>
            </Helmet>

            <div className="mt-10 md:15  px-mobile md:px-tablet xl:px-pc min-h-96">
                <h1 className="text-white text-2xl font-bold font-['kensmark-03'] mb-4">Today Best Deals</h1>
                {
                    loading ? <ProductsSkeleton></ProductsSkeleton> : <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 gap-2">
                    {
                        products.map((ele) => {
                            return <div className="max-w-[300px]"><ProductVertical data={ele}></ProductVertical></div>
                        })
                    }
                </div>
                }
    {
                    products.length === 0 && !loading ?
                    <div className="text-white text-lg text-center">Sorry, no products founds</div> : null
                } 
 
      
            </div>
            {
                loading ? 
                <>
                <Loader></Loader>
                <Ovarlay></Ovarlay>
                </>
                : null
            }
            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }
            <Footer></Footer>
            <Cart></Cart>
            <MobileMenu></MobileMenu>
            <BottomNav currentPage={""}></BottomNav>

            <ToastContainer />
        </>   
    )
}

export default TodayBestDeals;
