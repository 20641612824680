import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import api from "../statics/api.js";
import ProductVertical from "../components/product-vertical.component";


import Overlay from "../components/Overlay";

import SearchInput from "../components/search-input.component.jsx";
import Cart from "../components/cart.compontent.jsx";

import arrowLeftGray from "../assets/icons/arrow-left-gray.svg";
import arrowLeftColored from "../assets/icons/arrow-left-colored.svg";
import arrowRightGray from "../assets/icons/arrow-right-gray.svg";
import arrowRightColored from "../assets/icons/arrow-right-colored.svg";
import ProductHorizontal from "./product-horizontal.component.jsx";
import BuildYourPCPage from "../pages/BuildYourPC.jsx";
import BuildYourPCProductHorizontal from "./BuildYourPCProductHorizontal.jsx";

// import arrowLeft from "../assets/icons/arrow-left-gray.svg";
// import arrowRight from "../assets/icons/arrow-left-gray.svg";

function SelectProducsByCategory(props) {
  // const { query } = useParams();
  const  query  = props.categorySlug;

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const [productsLength, setProductsLength] = useState(0);
  const [searchStart, setSearchStart] = useState(0);

  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const Pagination = ({ numPages, currentPage }) => {
    const paginationElements = () => {
      if (numPages <= 9) {
        return Array.from({ length: numPages }, (_, i) => i + 1);
      } else if (currentPage >= 5) {
        return [
          currentPage,
          currentPage + 1,
          currentPage + 2,
          currentPage + 3,
          "...",
          numPages - 2,
          numPages - 1,
          numPages,
        ];
      } else {
        return [1, 2, 3, 4, "...", numPages - 2, numPages - 1, numPages];
      }
    };

    return (
      <>
        {paginationElements().map((page, index) => (
          <div
            key={index}
            onClick={() => {
              if (page !== "...") handlePageBtn(index + 1);
            }}
            className={`w-12  h-12 flex items-center justify-center  font-bold rounded-lg border-[1.5px]  ${
              page !== "..." ? "cursor-pointer" : ""
            } ${currentPage === page ? "border-gradient text-white" : "border-bw-03"}`}
          >
            {page}
          </div>
        ))}
      </>
    );
  };

  const handlePageBtn = (i) => {
    if (i > numPages || i <= 0) {
    } else {
      setCurrentPage(i);
      setSearchStart(i * 20 - 20);
      handleSearchWithFilters(i * 20 - 20);
      window.scrollTo(0,0)
    }
  };

  const loadData = async () => {
    setLoading(true);
    for (let i = 0; i < 100; i++) {
      try {
        let response = await fetch(
          `${api}get_category.php?category_slug=${query}&start=0&end=20`
        );
        let responseData = await response.text();
        responseData = JSON.parse(responseData);

        responseData.products = responseData.products.map((ele,index)=>{
          if (!ele.has_nocolors)  return {...ele, color: ele.colors.split(",")[0]}
          return {...ele, color: null}
        })
        setLoading(false);
        setProducts(responseData.products);
        setProductsLength(responseData.products_length);
        return;
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };

  const handleSearchWithFilters = async (start) => {
    setLoading(true);
    for (let i = 0; i < 100; i++) {
      try {
        let response = await fetch(
          `${api}get_category.php?category_slug=${query}&start=${start}&end=${
            start + 20
          }`
        );
        let responseData = await response.text();
        responseData = JSON.parse(responseData);
        setLoading(false);
        setProducts(responseData.products);
        setProductsLength(responseData.products_length);
        return;
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };

  const getNumPages = async () => {
    const x1 = productsLength / 20;
    const x2 = x1 % 1 ? 1 : 0;
    setNumPages(Math.floor(x1) + x2);
  };

  function handleSelectByCategory ()  {
    return  <div className="flex flex-col gap-2 ">
    {products.map((ele, index) => (
      <BuildYourPCProductHorizontal
        data={{...ele, qty: 1}}
        categorySlug={query}
        index={index}
        key={index}
      ></BuildYourPCProductHorizontal>
    ))}
  </div>
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getNumPages();
  }, [productsLength]);

  

  return (
    <>
      <main className="">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className=" grid md:grid-cols-[1fr] grid-cols-1 gap-4 ">
              <div className="xl:mt-8 flex flex-col gap-6">
                <div className="flex gap-2">
                  <span className="text-black font-['cairo'] ml-4">
                     {productsLength === 0 ? <span>No products found</span>: null} 
                  </span>
                </div>
                {products.length > 0 ? (
                  <>
                  {handleSelectByCategory()}
                    {/* Pages */}
                    <div className="flex gap-2 select-none items-center justify-center flex-wrap">
                      <div
                        onClick={() => handlePageBtn(currentPage - 1)}
                        className="cursor-pointer  w-12 h-12 flex justify-center items-center rounded-lg border-[1.5px] border-bw-03 "
                      >
                       {currentPage !== 1 ? (
                          <img src={arrowLeftColored} alt="icon"></img>
                        ) : (
                          <img src={arrowLeftGray} alt="icon"></img>
                        )}
                      </div>
                      <Pagination
                        numPages={numPages}
                        currentPage={currentPage}
                      />

                      <div
                        onClick={() => handlePageBtn(currentPage + 1)}
                        className=" cursor-pointer w-12 h-12 flex justify-center items-center rounded-lg border-[1.5px] border-bw-03 "
                      >
                        {currentPage !== numPages ? (
                          <img src={arrowRightColored} alt="icon"></img>
                        ) : (
                          <img src={arrowRightGray} alt="icon"></img>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <h1 className="text-white font-bold text-center">
                    No results found for: "{query}"
                  </h1>
                )}
              </div>
            </div>

          </>
        )}
      </main>
   
    </>
  );
}

export default SelectProducsByCategory;
