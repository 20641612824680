import { configureStore } from '@reduxjs/toolkit'
import usernameReducer from './reducers/username'
import cartReducer from './reducers/cart'
import homepageReducer from './reducers/home'
import mobilemenuReducer from './reducers/mobilemenu'
import buildYourPCReducer from './reducers/buildYourPC'



export default configureStore({
  reducer: {
    username: usernameReducer,
    cart: cartReducer,
    home: homepageReducer,
    mobilemenu: mobilemenuReducer,
    buildYourPC: buildYourPCReducer
  }
})