import { createSlice } from '@reduxjs/toolkit';

export const menuState = createSlice({
  name: 'mobilemenu',
  initialState: {
    state: false
  },
  reducers: {
    set_mobile_menu: (state, action) => {
      state.state = action.payload;
    },  
  }
});

// Action creators are generated for each case reducer function
export const { set_mobile_menu  } = menuState.actions;

export default menuState.reducer;
