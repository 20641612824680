import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        backgroundColor: '#FFFFFF',
    },
    header: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
    },
    logo: {
        width: 100,
        height: 50,
        marginBottom: 5,
    },
    locationText: {
        fontSize: 10,
        color: '#555',
    },
    title: {
        fontSize: 14,
        marginBottom: 10,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        margin: '10px 0',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: '#f3f3f3',
        padding: 8,
    },
    tableCol: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 8,
    },
    tableCell: {
        fontSize: 10,
        textAlign: 'center',
    },
    imageCell: {
        width: 50,
        height: 50,
        margin: 'auto',
    },
    totalTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        fontWeight: 'bold',
    },
    totalText: {
        fontSize: 12,
    },
});

const BundlePDF = ({ selectedProducts, storeLogo, storeLocation }) => {
    // Calculate grand total
    const grandTotal = selectedProducts.reduce(
        (acc, product) => acc + product.price * product.qty,
        0
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Store Logo and Location */}
                <View style={styles.header}>
                    {storeLogo && <Image style={styles.logo} src={storeLogo} />}
                    {storeLocation && <Text style={styles.locationText}>{storeLocation}</Text>}
                </View>

                <Text style={styles.title}>PuZzLe Technology Products Summary</Text>

                {/* Table Header */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Product Image</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Product Name</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Price (LE)</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Quantity</Text>
                        </View>

                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Total (LE)</Text>
                        </View>
                    </View>

                    {/* Table Rows */}
                    {selectedProducts.map((product, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                {product.img1 && <Image style={styles.imageCell} src={product.img1} />}
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{product.title}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{product.price.toLocaleString()}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{product.qty}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{(product.price * product.qty).toLocaleString()}</Text>
                            </View>
                        </View>
                    ))}

                    {/* Grand Total Row */}
                    <View style={styles.totalTextContainer}>
                        <Text style={styles.totalText}>Grand Total:</Text>
                        <Text style={styles.totalText}>{grandTotal.toLocaleString()} LE</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default BundlePDF;
