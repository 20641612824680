import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StarGold from "../assets/icons/star-gold.svg";
import StarGray from "../assets/icons/star-gray.svg";
import HeartWhite from "../assets/icons/heart-white.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import api from "../statics/api"
import { useDispatch } from "react-redux";
import { set_cart } from "../reducers/cart";
import { set_username } from "../reducers/username";
import { toast } from "react-toastify";

function ProductVertical(props) {
  const data = props.data;
  const goldStars = [];
  const grayStars = [];
  const [addedToWishlist, setAdded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hide,setHide] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Populate goldStars and grayStars arrays
  for (let i = 0; i < Math.floor(data.avg_rating); i++) {
    goldStars.push(<img key={i} src={StarGold} alt="gold star" />);
  }

  // If the average rating is not an integer, add one gray star
  if (data.avg_rating % 1 !== 0) {
    grayStars.push(<img key="gray" src={StarGray} alt="gray star" />);
  }

  // Fill the remaining gray stars up to 4
  const remainingGrayStars = 5 - Math.ceil(data.avg_rating);
  for (let i = 0; i < remainingGrayStars; i++) {
    grayStars.push(<img key={i + Math.floor(data.avg_rating)} src={StarGray} alt="gray star" />);
  }

  // Calculate discounted price and round to two decimal places
  let discountedPrice = data.price;
  if (!data.has_nodiscount && data.max_discount.type === "percent") {
    discountedPrice = (data.price * (1 - data.max_discount.value)).toFixed(2);
  }
  
  const  getCookie = (cookieName) => {
    var cookies = document.cookie.split(';');
  
    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if(cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }
    // If cookie not found, return null
    return false;
  }

  const addToWishList = async () => {
    let postData = new FormData();
    const session = getCookie("session");
  
    if (!session) {
      toast.error("You must log in first.");
      return;
    }
  
    postData.append('session', session);
    postData.append('product_sku', data.sku);
  
    try {
      let response = await fetch(`${api}add_to_wishlist.php`, {
        method: 'POST',
        body: postData
      });
  
      let responseData = await response.json();  // Directly parse JSON
  
      if (responseData.state) {
        toast.success("Product added to wishlist successfully");
        if (responseData.res.msg  &&responseData.res.msg === "New session") {
          const expiryDate = new Date(responseData.exp);
          document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
          localStorage.setItem("fullname", responseData.res.username);
          dispatch(set_username(localStorage.getItem("fullname")));
        }
        setAdded(true);
        return
      } else {
        toast.error(responseData.msg);
        return
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };
  
  const delFromWishlist = async () => {
    let postData = new FormData();
    const session = getCookie("session");
  
    if (!session) {
      toast.error("You must log in first.");
      return;
    }
  
    postData.append('session', session);
    postData.append('product_sku', data.sku);
  
    try {
      let response = await fetch(`${api}del_from_wishlist.php`, {
        method: 'POST',
        body: postData
      });
  
      let responseData = await response.json();  // Directly parse JSON
  
      if (responseData.state) {
        toast.success("Product deleted from wishlist successfully");
        // hide the product
        setHide(true);
        if (responseData.res.msg  &&responseData.res.msg === "New session") {
          const expiryDate = new Date(responseData.exp);
          document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
          localStorage.setItem("fullname", responseData.res.username);
          dispatch(set_username(localStorage.getItem("fullname")));
        }
        return
      } else {
        toast.error(responseData.msg);
        return
      }
    } catch (error) {
      console.error("Error deleyin from  wishlist:", error);
    }
  }
  
  return (
    <div
      className={"min-w-[150px] p-2 bg-bw-02 flex relative flex-col gap-2 rounded-lg flex-1 " + (hide && "hidden")}
      onMouseEnter={() => {setIsHovered(true);}}
      onMouseLeave={() => setIsHovered(false)}
      key={props.index}
    >
      <div className="relative group cursor-pointer" 
        onClick={()=>navigate("/product/" + data.slug)}
      >
        {!data.has_nodiscount && (
          <div className="absolute top-0 right-0 px-1 py-[2px] save-gradient w-14 rounded-tr-lg flex items-center justify-center z-20 ">
            <span className="text-white font-['cairo'] text-xs">
              {data.max_discount.type === "percent"
                ? `Save ${data.max_discount.value * 100}%`
                : `Save ${data.max_discount.value}`}
            </span>
          </div>
        )}
        <div className="w-full overflow-hidden rounded-lg relative min-h-24">
            {<img src={data.img1} alt="product_image" className="transition-all duration-200 group-hover:opacity-0 aspect-w-1 aspect-h-1" /> }
            <img src={data.img2} alt="product_image" className=" absolute aspect-w-1 aspect-h-1 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-0  delay-150  scale-100 overflow-hidden group-hover:opacity-100 rounded-lg transition-all duration-200" />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex">
          {goldStars}
          {grayStars}
          <span className="text-white">({data.num_reviews || 0})</span>
        </div>
        {!addedToWishlist && !props.inWishList &&(
          <img
            src={HeartWhite}
            alt="ico"
            onClick={() => {
              setAdded(true);
              addToWishList();
            }}
          />
        )}
        {
          props.inWishList && <img
            src={DeleteIcon}
            alt="ico"
            className="cursor-pointer"
            onClick={delFromWishlist}
          />
        }
      </div>

      <span className="text-sm text-white font-['poppins'] leading-[18px]">
        {data.title.length > 50 ? data.title.slice(0, 50) + "......" : data.title}
      </span>
      <div className="gap-2 flex items-center font-['poppins'] mt-auto ">
        <span className="text-base font-bold text-white leading-[18px]">
          {discountedPrice} LE
        </span>
        {!data.has_nodiscount && (
          <span className="text-xs text-bw-04 line-through">{data.price} LE</span>
        )}
      </div>
      {data.colors ? (
        <div className="flex flex-col absolute bottom-2 right-2 z-30 gap-1">
          {data.colors.split(",").map((ele) => {
            return <span style={{ backgroundColor: ele }} className={` w-3 h-3 rounded-full`}></span>;
          })}
        </div>
      ) : null}
    </div>
  );
}

export default ProductVertical;
