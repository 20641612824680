import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StarGold from "../assets/icons/star-gold.svg";
import StarGray from "../assets/icons/star-gray.svg";
import HeartWhite from "../assets/icons/heart-white.svg";
import CloseCircle from "../assets/icons/close-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { remove, add, select_color, set
 } from "../reducers/buildYourPC.js";

function BuildYourPCProductHorizontal(props) {
  const selectedProducts = useSelector((state)=> state.buildYourPC.products);
  const [data, setData] = useState({
    ...props.data,
    discount: props.discount ? props.discount : {}
  });
  
  const goldStars = [];
  const grayStars = [];
  const [addedToWishlist, setAdded] = useState(false);
  const [discount, setDiscount] = useState(props.data.discount ||  {})
  const dispatch = useDispatch();

  // Populate goldStars and grayStars arrays
  for (let i = 0; i < Math.floor(data.avg_rating); i++) {
    goldStars.push(<img key={`gold-${i}`} src={StarGold} alt="gold star" />);
  }
  // Fill the remaining gray stars up to 5
  for (let i = 0; i < 5 - Math.floor(data.avg_rating); i++) {
    grayStars.push(<img key={`gray-${i}`} src={StarGray} alt="gray star" />);
  }


  const isProductSelected = (sku) => {
    return (
      selectedProducts.find((product) => product.sku === sku) !== undefined
    );
  };

  const checkDiscount = (color = data.color) => {

    for (const discount of data.discounts) {
      if (discount.hexcode === color) {
        
        return discount;
      }
    }
    return {}; // Return an empty object if no discount is found
  }
  
  const handleColorChange = (color) => {


      let discount = checkDiscount(color);
      if (discount !== data.discount) {
        if (isProductSelected(data.sku)) {
          // Update local and GLobal
          if (!discount.value) {
            setData({...data, color: color, discountedPrice: data.price, discount: {}})
            const oldData = [...selectedProducts];
            let newData = oldData.map((ele,index) => {
              // Update Color and Discount Globaly iin the slice
              if (ele.sku === data.sku) {
                return {...ele, discount: {}, discountedPrice: data.price, color: color}
              }else {
                return ele
              }
            })
            dispatch(set(newData))
          }

          if (discount.type === "percent") {
            setData({...data, color: color, discount: discount, discountedPrice: (1 - discount.value ) * data.price})
            const oldData = [...selectedProducts];
            let newData = oldData.map((ele,index) => {
              // Update Color and Discount Globaly iin the slice
              if (ele.sku === data.sku) {
                return {...ele, discount: discount, discountedPrice: (1 - discount.value ) * data.price, color: color}
              }else {
                return ele
              }
            })
            dispatch(set(newData))
          }
        }else {
          // console.log("Update Locally")
          // Update lLocally in teh componemnt
          if (!discount.value) {
            setData({...data, color: color, discountedPrice: data.price, discount: {}})
          }
          if (discount.type === "percent") {
            setData({...data, color: color, discount: discount, discountedPrice: (1 - discount.value ) * data.price})
          }
        }
      }
  }

  useEffect(()=>{

    if (isProductSelected(data.sku)) {
      const oldData  = [...selectedProducts];
      let newData = oldData.map((ele)=> {
        if (ele.sku === data.sku) {
          if (!ele.color !== data.color) {
            setData({...data, color: ele.color,  discount: ele.discount, discountedPrice: ele.discountedPrice})
            
          }
        }
      })
    }
  },[])


  useEffect(()=>{
    if (data.discounts.length > 0 && data.has_nocolors && (!data.discount.value) && !data.discountedPrice) {
      if (data.discount.value) {
      }else {
        const discount = checkDiscount(null)
        if (discount.type ==="percent") {
          setData({...data, discount: discount, discountedPrice: (1 - discount.value ) * data.price })
          const oldData = [...selectedProducts];
          let newData = oldData.map((ele,index) => {
            // Update Color and Discount Globaly iin the slice
            if (ele.sku === data.sku) {
              console.log("UPDATEEEEEEEEEEEEE")
              return {...ele, discount: discount, discountedPrice: (1 - discount.value ) * data.price, color: data.color}
            }else {
              return ele
            }
          })
          dispatch(set(newData))
        }
      }
    }else if (data.discounts.length > 0 && data.colors && (!data.discount.value) && !data.discountedPrice) {
      handleColorChange(data.colors.split(",")[0])
    }
   
     
  },[])

  return (
    <div
      className={`relative p-2 s shadow-lg flex gap-2  duration-200  flex-1 items-center  md:items-start  ${
        props.bgTransparent ? "bg-black rounded-lg" : "bg-white rounded-lg "
      } `}
    >
      <img
        src={data.img1}
        alt="product_image"
        className="rounded-lg w-16 h-16 xl:w-[124px] xl:h-[124px] "
      />
      <div className="flex flex-col gap-2 flex-1">
        <div className="flex justify-between">
          <div className="flex">
            {goldStars}
            {grayStars}
            <span className="">({data.num_reviews || 0})</span>
          </div>
          {!addedToWishlist && !props.bgTransparent && (
            <img
              src={HeartWhite}
              alt="wishlist icon"
              onClick={() => {
                setAdded(true);
              }}
            />
          )}
        </div>
        <span className="text-sm font-['poppins'] leading-[18px] ">
          {data &&  data.title.length > 50
            ? data.title.slice(0, 50) + "......"
            : data.title}
        </span>
        {!data.has_nodiscount && data.discount.value  &&(
          <div className="px-1 py-[2px] save-gradient w-14 rounded-[4px] flex items-center justify-center">
            <span className="font-['cairo'] text-xs">
              {data.discount.type === "amount"
                ? `Save ${data.discount.value} LE`
                : `Save ${data.discount.value * 100}%`}
            </span>
          </div>
        )}
         {props.bgTransparent &&  <div className="flex items-center gap-4">
                <span className="font-['cairo'] text-lg  font-bold">
                 {(data.colors && data.colors !== undefined ) && "Color:" }
                </span>
                <div className="flex gap-1">
                  {(data.colors && data.colors !== undefined ) && data.colors.split(',').map((color, index) => (
                    <div
                      key={index}
                      className={`w-8 h-8 relative rounded-lg ${
                        data.color === color ? "border-gradient" : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onClick={() => {
                        handleColorChange(color);
                      }}
                    >
                      <div
                        className="absolute inset-0  rounded-lg"
                        style={{ backgroundColor: color }}
                      ></div>
                    </div>
                  ))}
                </div>
          </div>
          } 

        <div className="gap-2 flex items-center font-['poppins'] flex-1">
          {!data.has_nodiscount && data.discount.value ? <span className="text-base font-bold leading-[18px]">
            {data.discountedPrice} LE
          </span> :
          <span className="text-base font-bold leading-[18px]">
            {data.price} LE
          </span>
          }
          {!data.has_nodiscount && data.discount.value  && (
            <span className="text-xs text-bw-04 line-through">
              {data.price} LE
            </span>
          )}
        </div>
      </div>

      <div className="absolute bottom-2 right-2 flex flex-col items-center gap-1">
        {data.colors &&
          data.colors.split(",").map((color) => (
            <div
              key={color}
              onClick={() => handleColorChange(color)}
              style={{
                backgroundColor: color,
                border: color === "#ffffff" ? "1px solid black" : "none",
                width: data.color === color ? "14px" : "12px",
                height: data.color === color ? "14px" : "12px",
              }}
              className={`w-3 h-3 rounded-full relative cursor-pointer ${data.color === color ? "border-gradient" : ""}`}
            >
              {data.color === color && (
                <div className="absolute inset-0 rounded-lg" style={{ backgroundColor: data.color }}></div>
              )}
            </div>
          ))}
      </div>

      {props.bgTransparent ? (
        <img
          src={CloseCircle}
          className="absolute top-2 right-2 cursor-pointer"
          onClick={() => dispatch(remove(data.sku))}
          alt="remove_icon"
        />
      ) : isProductSelected(data.sku) ? (
        <button
          onClick={() => dispatch(remove(data.sku))}
          className="bg-red text-white font-['inter'] rounded-md absolute top-2 right-2 w-[86px] h-[24px]"
        >
          Deselect
        </button>
      ) : (
        <button
          onClick={() =>
            dispatch(add({ ...props.data, categorySlug: props.categorySlug, qty: 1, discount: data.discount, discountedPrice: data.discountedPrice }))
          }
          className="bg-blue text-white font-['inter'] rounded-md absolute top-2 right-2 w-[86px] h-[24px]"
        >
          Select
        </button>
      )}
      
    </div>
  );
}

export default BuildYourPCProductHorizontal;
