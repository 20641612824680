import { useState, useRef } from "react";

function OTPInput({ length = 6, onChange }) {
    const [otp, setOtp] = useState(new Array(length).fill(""));
    const inputRefs = useRef([]);

    const handleChange = (element, index) => {
        const value = element.value;
        if (/[^0-9]/.test(value)) return; // Only allow numeric input

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Focus next input
        if (value && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }

        if (onChange) {
            onChange(newOtp.join(""));
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const allInputsFilled = otp.every(value => value);

    return (
        <div className="otp-input-container flex gap-2">
            {otp.map((value, index) => (
                <div key={index} className="flex items-center">
                    <div className={`${allInputsFilled ? "border-gradient" : "border-none"}`}>
                        <div className={`border-solid border-[1.5px] ${allInputsFilled ? "border-bw-03" : "border-none"} rounded-[8px] flex justify-center items-center w-12 h-[48px] bg-black`}>
                            <input
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onFocus={(e) => e.target.select()}
                                placeholder="-"
                                className="otp-input bg-transparent border-none outline-none flex-1 font-['cairo'] text-xs caret-white text-white placeholder:text-bw-04 text-center"
                            />
                        </div>
                    </div>
                    {(index + 1) % 3 === 0 && index + 1 < length && (
                        <span key={`dash-${index}`} className="text-center ml-2 text-white">-</span>
                    )}
                </div>
            ))}
        </div>
    );
}

export default OTPInput;
